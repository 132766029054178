<div class="card selectedNavigationItem" (click)="viewSpecificJob()">
  <div class="section" fxLayout="column">
    <div fxLayout="column" fxFlex="100%" fxFlexFill matRipple [matRippleCentered]="true">
      <div fxLayout="row" class="status-section  {{ model.status.cssClassName }}" *ngIf="showStatusBar">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlexOffset="15" fxFlex="70" class="status-test-section">
          <span class="smaller-card-text status-head">{{ model.status.statusText }}</span>
          <span *ngIf="model.status.statusDescription !== ''" class="smaller-card-text">{{ model.status.statusDescription }}</span>
        </div>
        <div fxLayout="column" fxFlex fxLayoutAlign="center end">
          <mat-icon color="primary" (click)="clickEvent($event); model.status.statusIconFunction()"> {{ model.status.statusIcon }}</mat-icon>
        </div>
      </div>
      <div class="details-section" fxLayout="column" fxLayoutAlign="space-between">
        <div class="job-section" fxLayout="row">
          <div fxFlex="100%" fxLayout="column">
            <div *ngIf="model.noLongerAvailable" class="no-longer-available-info">
              <img src="assets/img/minus-circle.svg" />NO LONGER AVAILABLE <span>{{ formatDate(model.noLongerAvailableDate) }}</span>
            </div>
            <span class="card-job-title">{{ model.title }}</span>
            <span class="card-subtitle">{{ model.subtitle }}</span>
            <span class="card-location"
              ><span>{{ model.location }}</span></span
            >
          </div>
          <div fxLayout="row" fxLayoutAlign="end start">
            <button mat-icon-button [disabled]="disabled" class="card-icon" color="primary" (click)="icon.clickFunction($event)" *ngFor="let icon of model.icons">
              <mat-icon color="primary" [ngClass]="icon.className">{{ icon.iconName }}</mat-icon>
            </button>
          </div>
        </div>
        <div fxLayout="row" fxFlex="100%" [ngClass]="{ panel: !onlyPanels, 'panel-bottom': onlyPanels }">
          <div class="panel-item justify-content-center job-detail" fxLayout="column" fxFlex="33%">
            <span class="small-panel-label">{{ model.leftPanel.label }}</span>
            <span class="small-panel-value">{{ model.leftPanel.value }}</span>
          </div>
          <div class="panel-item justify-content-center job-detail center-panel" fxLayout="column" fxFlex="33%">
            <span class="small-panel-label">{{ model.centerPanel.label }}</span>
            <span class="small-panel-value">{{ model.centerPanel.value }}</span>
          </div>
          <div class="panel-item justify-content-center job-detail" fxLayout="column" fxFlex="33%">
            <span class="small-panel-label">{{ model.rightPanel.label }}</span>
            <span class="small-panel-value">{{ model.rightPanel.value }}</span>
          </div>
        </div>
        <div fxLayout="row" class="badge-section" *ngIf="showBadges && model.badges.length > 0">
          <div *ngIf="model.badges.length > 0" fxLayout="row wrap" fxLayoutAlign="start center">
            <span *ngFor="let badge of model.badges">
              <img *ngIf="badge.imagePath" class="badge" src="{{ badge.imagePath }}" alt="{{ badge.badge.title }}" />
              <hc-badge *ngIf="!badge.imagePath" color="{{ badge.badge.color }}">{{ badge.badge.title }}</hc-badge>
            </span>
          </div>
        </div>
        <div *ngIf="showTransferApp && model.transferApp !== undefined">
          <div *ngIf="model.transferApp.jobOrderInactiveDate && model.transferApp.matchingInactiveJobOrder" class="full-width standard-padding" fxLayoutAlign="center center">
            <button
              id="submittalTransferApplication"
              mat-flat-button
              color="accent"
              class="full-width"
              (click)="model.transferApp.transferFunction($event, model.transferApp.matchingInactiveJobOrder)"
            >
              {{ model.transferApp.transferName }}
            </button>
          </div>
        </div>
        <div class="button-section" fxLayout="row" fxLayoutGap="30px" *ngIf="showLinks && model.links.length > 0">
          <button mat-button color="primary" class="submittal-btn" [disabled]="disabled" *ngFor="let link of model.links" (click)="link.linkFunction($event)">
            {{ link.linkName }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
