import { Injectable, OnDestroy } from '@angular/core';
import {
  Education,
  Hospital,
  IFilterGeneral,
  IJob,
  IJobFilter,
  IJobFilterAPI,
  ILookup,
  NewReference,
  NurseModel,
  Submittal,
  Technology,
  WorkHistory
} from '../common';
import { radiusOptions } from '../common/constants';
import { UserTraits } from '../common/models/user-traits';
import { NurseTask } from '../common/models/db-objects';
import { TaskStatusConstants } from '../common/models/task-status';
import { portalCategoryCodeToNameMap } from '../common/models/portal-category';
import { Skill } from '../common/models/skill';
import { CandidateSkill } from '../common/models/candidate-skill';
import { Capacitor } from '@capacitor/core';
import { GetAvailableJobsSuccess } from '../store/jobs/jobs.actions';
import { IAppState } from '../store/app/app.state';
import { Store } from '@ngrx/store';
import { selectSegmentStaffingName } from '../store/userContext/userContext.selectors';
import { Subject, takeUntil } from 'rxjs';

export const jobsSearchedEventName = 'Jobs Searched';
export const jobViewedEventName = 'Job Viewed';
export const jobSavedEventName = 'Job Saved';
export const applicationStartedEventName = 'Application Started';
export const savedJobsViewedEventName = 'Saved Jobs Viewed';
export const applicationsViewedEventName = 'Applications Viewed';
export const applicationArchivedEventName = 'Application Archived';
export const navigationItemClickedEventName = 'Navigation Item Clicked';
export const signedUpEventName = 'Signed Up';
export const signedInEventName = 'Signed In';
export const signedOutEventName = 'Signed Out';
export const profileTasksViewedEventName = 'Profile Tasks Viewed';
export const callToActionEventName = 'Call To Action Clicked';
export const onboardingStepViewedEventName = 'Onboarding Step Viewed';
export const onboardingStepCompletedEventName = 'Onboarding Step Completed';
export const onboardingCompletedEventName = 'Onboarding Completed';
export const jobPreferencesCompletedEventName = 'Job Preferences Completed';
export const licensesViewedEventName = 'Licenses Viewed';
export const licenseAddedEventName = 'License Added';
export const licenseDeletedEventName = 'License Deleted';
export const licenseVerifiedEventName = 'License Verified';
export const documentUploadedEventName = 'Document Uploaded';
export const blsUploadedEventName = 'BLS Uploaded';
export const technologiesViewedEventName = 'Technologies Viewed';
export const technologyAddedEventName = 'Technology Added';
export const technologyDeletedEventName = 'Technology Deleted';
export const referenceAddedEventName = 'Reference Added';
export const professionalOverviewAddedEventName = 'Professional Overview Added';
export const backgroundQuestionnaireCompletedEventName = 'Background Questionnaire Completed';
export const tasksViewedEventName = 'Tasks Viewed';
export const taskCompletedEventName = 'Task Completed';
export const workHistoryAddedEventName = 'Work History Added';
export const workHistoryDeletedEventName = 'Work History Deleted';
export const workHistoriesViewedEventName = 'Work Histories Viewed';
export const educationAddedEventName = 'Education Added';
export const educationDeletedEventName = 'Education Deleted';
export const educationsViewedEventName = 'Educations Viewed';
export const facilityViewedEventName = 'Facility Viewed';
export const skillChecklistViewedEventName = 'Skill Checklist Viewed';
export const skillChecklistCompletedEventName = 'Skill Checklist Completed';
export const phoneNumberClickedEventName = 'Phone Number Clicked Viewed';
export const externalLinkClickedEventName = 'External Link Clicked Viewed';
export const internalLinkClickedEventName = 'Internal Link Clicked Viewed';

@Injectable({
  providedIn: 'root'
})
export class SegmentService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  staffingName: string = '';

  constructor(private readonly _store: Store<IAppState>) {
    this._store
      .select(selectSegmentStaffingName)
      .pipe(takeUntil(this.destroy$))
      .subscribe((staffingName: string) => {
        this.staffingName = staffingName;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  identify(id: string, traits: UserTraits) {
    if (id) {
      Segment.identify(id, traits);
    } else {
      Segment.identify(traits);
    }
  }

  track(eventName: string, properties?: object) {
    if (properties === undefined || properties === null) {
      properties = {};
    }
    if (this.staffingName !== '') {
      properties['staffingNetwork'] = this.staffingName;
      properties['staffingBrand'] = this.staffingName;
    }

    Segment.track(eventName, properties);
  }

  storeBasedIdentify(
    nurse: NurseModel,
    professionLookup: Map<string, ILookup<string>>,
    specialtyLookup: Map<string, ILookup<string>>,
    stateLookup: Map<string, ILookup<string>>,
    tasks: NurseTask[]
  ) {
    const id = nurse.merlinId;
    const profession = professionLookup.get(nurse.professionId);
    const specialty = specialtyLookup.get(nurse.specialtyId);
    const subSpecialty = specialtyLookup.get(nurse.subSpecialtyId);
    const state = stateLookup.get(nurse.homeState);
    const tasksCompleted = tasks?.filter(task => task.status === TaskStatusConstants.complete).length;
    const tasksOutstanding = tasks?.filter(task => task.status !== TaskStatusConstants.complete).length;
    const traits: UserTraits = {
      createdAt: nurse.createdDate,
      email: nurse.email,
      firstName: nurse.firstName,
      lastName: nurse.lastName,
      address: {
        streetAddress1: nurse.homeAddress1,
        streetAddress2: nurse.homeAddress2
      },
      city: nurse.homeCity,
      state: state?.name,
      zipcode: nurse.homeZipCode?.zipCode,
      birthday: nurse.dateOfBirth,
      merlinID: nurse.merlinId,
      phone: nurse.mobileNumber,
      profession: profession?.name,
      specialty: specialty?.name,
      subSpecialty: subSpecialty?.name,
      username: nurse.email,
      candidateStage: nurse.lifeCycleStage,
      candidateSubStage: nurse.lifeCycleSubStage,
      onAssignment: (nurse.hasAssignment === true).toString(),
      portalStatus: nurse.portalStatus,
      isNativeApp: Capacitor.isNativePlatform(),
      tasksCompleted,
      tasksOutstanding
    };

    if (this.staffingName !== '') {
      traits['staffingNetwork'] = this.staffingName;
      traits['staffingBrand'] = this.staffingName;
    }

    this.identify(id, traits);
  }

  softRegister(email: string) {
    this.identify(null, { email });
  }

  jobsSearched(
    jobFilter: IJobFilter | IJobFilterAPI,
    weeklyHoursLookup: Map<number, ILookup<number>>,
    traumaLevelLookup: Map<number, ILookup<string>>,
    professionLookup: Map<string, ILookup<string>>,
    shiftLookup: Map<number, ILookup<number>>,
    assignmentLengthsLookup: Map<number, ILookup<number>>,
    startDatesLookup: Map<number, ILookup<number>>,
    bedSizeLookup: Map<number, ILookup<number>>,
    erVisitsPerYearLookup: Map<number, ILookup<number>>,
    birthsPerYearLookup: Map<number, ILookup<number>>,
    actionResults: GetAvailableJobsSuccess
  ) {
    const profession = professionLookup.get(jobFilter.profession?.value?.id);
    const radiusString = radiusOptions[jobFilter.radius] ? `${radiusOptions[jobFilter.radius]}mi` : undefined;
    const facilityTypes = jobFilter.facilityTypes?.map(s => s['key']);
    const technologies = jobFilter.technologies?.map(s => s['key']);
    const properties = {
      resultsReturned: actionResults.totalCount,
      contractType: jobFilter.contractType,
      profession: profession?.name,
      speciality: jobFilter.specialties?.map(s => s['key']),
      location: jobFilter.locations?.length ? jobFilter.locations : undefined,
      radius: radiusString,
      shift: this.getValuesFromLookupForCheckboxes(jobFilter.shift, shiftLookup),
      assignmentLength: this.getValuesFromLookupForCheckboxes(jobFilter.assignmentLength, assignmentLengthsLookup),
      hoursPerWeek: this.getValuesFromLookupForCheckboxes(jobFilter.hoursPerWeek, weeklyHoursLookup),
      facilityType: facilityTypes?.length ? facilityTypes : undefined,
      startDate: this.getValuesFromLookupForCheckboxes(jobFilter.startDate, startDatesLookup),
      traumaCenter: this.getValuesFromLookupForCheckboxes(jobFilter.traumaLevel, traumaLevelLookup),
      technologiesUsed: technologies?.length ? technologies : undefined,
      bedSize: this.getValuesFromLookupForCheckboxes(jobFilter.bedSize, bedSizeLookup),
      erVisitsPerYear: this.getValuesFromLookupForCheckboxes(jobFilter.erVisits, erVisitsPerYearLookup),
      birthsPerYear: this.getValuesFromLookupForCheckboxes(jobFilter.births, birthsPerYearLookup),
      weeklyPay: jobFilter.minimumPay ?? undefined,
      acceptsLocalTravellers: this.convertBoleanIFilterGeneralToString(jobFilter.localTraveler),
      acceptsFirstTimeTravellers: this.convertBoleanIFilterGeneralToString(jobFilter.firstTimeTraveler),
      multistateLicensure: this.convertBoleanIFilterGeneralToString(jobFilter.multiStateLicensure),
      temporaryLicenseStates: this.convertBoleanIFilterGeneralToString(jobFilter.tempLicenseStates),
      teachingFacility: this.convertBoleanIFilterGeneralToString(jobFilter.teachingFacility),
      magnetHospitals: this.convertBoleanIFilterGeneralToString(jobFilter.anccMagnetHospitalCode),
      exclusiveDiverseProgram: this.convertBoleanIFilterGeneralToString(jobFilter.exclusiveDiverseProgram)
    };
    if (jobFilter.facilities) {
      properties['facilityName'] = jobFilter.facilities.map(f => f?.facilityName).join(', ');
    }
    this.track(jobsSearchedEventName, properties);
  }

  jobViewed(job: IJob, stateLookup: Map<string, ILookup<string>>, shiftLookup: Map<number, ILookup<number>>) {
    const state = stateLookup.get(job.state);
    const shift = shiftLookup.get(job.shift);
    const properties = {
      jobID: job.id,
      jobOrder: job.name,
      facilityName: job.hospitalInfo?.name,
      city: job.city,
      state: state?.name,
      shift: shift?.name,
      startDate: job.startDate,
      hoursPerWeek: job.hoursPerWeek,
      duration: job.assignmentLength > 0 ? `${job.assignmentLength} weeks` : undefined,
      estimatedGrossPay: job.estGrossPay,
      facilityID: job.hospitalInfo?.id
    };
    this.track(jobViewedEventName, properties);
  }

  jobSaved(jobId: string) {
    const properties = {
      jobID: jobId
    };
    this.track(jobSavedEventName, properties);
  }

  savedJobsViewed() {
    this.track(savedJobsViewedEventName);
  }

  applicationStarted(jobId: string) {
    const properties = {
      jobID: jobId
    };
    this.track(applicationStartedEventName, properties);
  }

  applicationsViewed(numberOfApplications: number) {
    const properties = {
      numberOfApplications
    };
    this.track(applicationsViewedEventName, properties);
  }

  applicationArchived(application: Submittal, job: IJob, stateLookup: Map<string, ILookup<string>>, shiftLookup: Map<number, ILookup<number>>) {
    const state = stateLookup.get(job.state);
    const shift = shiftLookup.get(job.shift);
    const properties = {
      accountType: 'Recruited',
      applicationID: application.id,
      jobName: application.jobName,
      facilityLocation: state ? `${application.city}, ${state.code}` : application.city,
      facilityState: state?.name,
      jobID: application.jobOrderId,
      startDate: application.startDate,
      endDate: job.endDate,
      hoursPerWeek: application.hoursPerWeek,
      shift: shift?.name,
      jobAssigmentLength: job.assignmentLength > 0 ? `${job.assignmentLength} weeks` : undefined
    };
    this.track(applicationArchivedEventName, properties);
  }

  navigationItemClicked(navigationText, navigationPlacement, navigationUrl) {
    const properties = {
      navigationText,
      navigationPlacement,
      navigationUrl
    };
    this.track(navigationItemClickedEventName, properties);
  }

  signedUp() {
    this.track(signedUpEventName);
  }

  signedIn() {
    this.track(signedInEventName);
  }

  signedOut() {
    this.track(signedOutEventName);
  }

  profileTasksViewed() {
    this.track(profileTasksViewedEventName);
  }

  callToActionClicked(pageURL: string, pageTitle: string, destinationURL: string, ctaText: string) {
    const properties = {
      pageURL,
      pageTitle,
      destinationURL,
      ctaText
    };
    this.track(callToActionEventName, properties);
  }

  onboardingStepViewed(stepName: string, stepNumber: number) {
    const properties = {
      stepName,
      stepNumber
    };

    this.track(onboardingStepViewedEventName, properties);
  }

  onboardingStepCompleted(stepName: string, stepNumber: number, stepSkipped: boolean) {
    const properties = {
      stepName,
      stepNumber,
      stepSkipped
    };
    this.track(onboardingStepCompletedEventName, properties);
  }

  onboardingCompleted(stepName: string, stepNumber: number) {
    const properties = {
      stepName,
      stepNumber
    };
    this.track(onboardingCompletedEventName, properties);
  }

  jobPreferencesCompleted() {
    this.track(jobPreferencesCompletedEventName);
  }

  licenseAdded() {
    this.track(licenseAddedEventName);
  }

  licensesViewed() {
    this.track(licensesViewedEventName);
  }

  licenseDeleted(itemID: string) {
    const properties = {
      itemID
    };
    this.track(licenseDeletedEventName, properties);
  }

  licenseVerified() {
    this.track(licenseVerifiedEventName);
  }

  documentUploaded(documentID: string, documentName: string, documentType: string, documentURL: string) {
    const properties = {
      documentID,
      documentName,
      documentType,
      documentURL
    };
    this.track(documentUploadedEventName, properties);
  }

  blsUploaded() {
    this.track(blsUploadedEventName);
  }

  technologiesViewed() {
    this.track(technologiesViewedEventName);
  }

  technologyAdded(technologies: Technology[], technologyLookup: Map<string, ILookup<string>>, proficencyLookup: Map<number, ILookup<any>>) {
    technologies.forEach(technology => {
      const technologySkill = technologyLookup.get(technology.technology)?.systemTechnology;
      const proficiency = proficencyLookup.get(technology.proficiency)?.name;
      const properties = {
        technologySkill,
        proficiency
      };
      this.track(technologyAddedEventName, properties);
    });
  }

  technologyDeleted(itemID: string) {
    const properties = {
      itemID
    };
    this.track(technologyDeletedEventName, properties);
  }

  referenceAdded(reference: NewReference, positionTitleLookup: Map<number, any>, workHistoryList: WorkHistory[]) {
    const workHistory = workHistoryList.find(workHistory => workHistory.id === reference.workHistoryId);
    const positionTitle = positionTitleLookup.get(reference.positionCode);
    const properties = {
      firstName: reference.firstName,
      lastName: reference.lastName,
      position: positionTitle.name,
      email: reference.email,
      phone: reference.phone,
      facility: workHistory.employerName
    };
    this.track(referenceAddedEventName, properties);
  }

  professionalOverviewAdded() {
    this.track(professionalOverviewAddedEventName);
  }

  backgroundQuestionnaireCompleted() {
    this.track(backgroundQuestionnaireCompletedEventName);
  }

  tasksViewed(tasksType: 'apply' | 'pay' | 'all', tasks: NurseTask[]) {
    if (tasksType !== 'all') {
      tasks = [...tasks.filter(task => (tasksType === 'apply' ? task.requiredForApply : task.requiredForPay))];
    }
    const properties = {
      tasksOpen: tasks.filter(task => task.status !== TaskStatusConstants.complete).length,
      tasksCompleted: tasks.filter(task => task.status === TaskStatusConstants.complete).length,
      totalTasks: tasks.length
    };
    this.track(tasksViewedEventName, properties);
  }

  taskCompleted(tasks: NurseTask[], previousTasks: NurseTask[]) {
    const completedPreviousTasksMap = {};
    previousTasks.forEach(task => {
      if (task.status === TaskStatusConstants.complete) completedPreviousTasksMap[task.complianceRequirementId] = true;
    });
    tasks.forEach(task => {
      if (task.status === TaskStatusConstants.complete && !completedPreviousTasksMap.hasOwnProperty(task.complianceRequirementId)) {
        const types = task.portalCategoryCodes?.map(code => portalCategoryCodeToNameMap.get(code)).join(' | ');
        const properties = {
          taskID: task.complianceRequirementId,
          taskName: task.friendlyName,
          taskType: types
        };
        this.track(taskCompletedEventName, properties);
      }
    });
  }

  workHistoryAdded(
    workHistory: WorkHistory,
    specialties: Map<string, ILookup<string>>,
    professions: Map<string, ILookup<string>>,
    professionTypes: Map<string, ILookup<string>>,
    yesNo: Map<number, string>,
    states: Map<string, ILookup<string>>
  ) {
    const state = states.get(workHistory.employerState);
    const profession = professions.get(workHistory.profession);
    const specialty = specialties.get(workHistory.specialtyId);
    let professionType;
    for (let [key, value] of professionTypes.entries()) {
      if (Number(value.id) === workHistory.professionType) {
        professionType = key;
      }
    }
    const roles = [];
    if (yesNo.get(Number(workHistory.chargeExperience)) === 'Yes') {
      roles.push('Charge');
    }
    if (yesNo.get(Number(workHistory.float)) === 'Yes') {
      roles.push('Float');
    }
    if (yesNo.get(Number(workHistory.preceptorExperience)) === 'Yes') {
      roles.push('Preceptor');
    }
    const currentPositionId = Number(workHistory.currentPosition);
    const currentPosition = yesNo.get(currentPositionId) === 'Yes' ? true : false;
    const properties = {
      employerName: workHistory.employerName,
      employerCity: workHistory.employerCity,
      employerState: state?.name,
      positionTitle: profession?.name,
      specialty: specialty?.name,
      employmentType: professionType,
      shiftsPerMonth: workHistory.shiftsPerMonth,
      patientRatio: workHistory.patientRatio,
      startDate: workHistory.startDate,
      endDate: !currentPosition ? workHistory.endDate : null,
      currentPosition: currentPosition.toString(),
      jobDescription: workHistory.description1,
      role: roles.join(' | ')
    };
    this.track(workHistoryAddedEventName, properties);
  }

  workHistoriesViewed() {
    this.track(workHistoriesViewedEventName);
  }

  workHistoryDeleted(itemID: string) {
    const properties = {
      itemID
    };
    this.track(workHistoryDeletedEventName, properties);
  }

  educationAdded(education: Education, countryLookup: Map<string, ILookup<string>>) {
    const country = countryLookup.get(education.country)?.name ?? null;
    const city = education.city?.trim().length ? education.city : null;
    const properties = {
      schoolAttended: education.schoolAttended,
      graduationDate: education.graduationDate,
      degree: education.degreeStringName,
      country: country,
      city: city,
      state: null
    };
    this.track(educationAddedEventName, properties);
  }

  educationsViewed() {
    this.track(educationsViewedEventName);
  }

  educationDeleted(itemID: string) {
    const properties = {
      itemID
    };
    this.track(educationDeletedEventName, properties);
  }

  facilityViewed(hospital: Hospital, stateLookup: Map<string, ILookup<string>>) {
    const state = stateLookup.get(hospital.state);
    const properties = {
      facillityID: hospital.id,
      facilityName: hospital.name,
      facilityState: state?.name,
      facilityType: hospital.hospitalType
    };
    this.track(facilityViewedEventName, properties);
  }

  skillChecklistViewed(skill: Skill | ILookup<string>) {
    const skillID = (skill as Skill).skillId ? (skill as Skill).skillId : (skill as ILookup<string>).id;
    const properties = {
      skillID,
      skillName: skill.name
    };
    this.track(skillChecklistViewedEventName, properties);
  }

  skillChecklistCompleted(skill: Skill | CandidateSkill | ILookup<string>) {
    const skillID = (skill as Skill).skillId || (skill as CandidateSkill).skillId || (skill as ILookup<string>).id;
    const skillName = (skill as Skill).name || (skill as CandidateSkill).skillName || (skill as ILookup<string>).id;
    const properties = {
      skillID,
      skillName
    };
    this.track(skillChecklistCompletedEventName, properties);
  }

  phoneNumberClicked(location: string, phoneType: string, phoneNumber: string) {
    const properties = {
      location,
      phoneType,
      phoneNumber
    };
    this.track(phoneNumberClickedEventName, properties);
  }

  externalLinkClicked(pageURL: string, ctaText: string, clickURL: string) {
    const properties = {
      pageURL,
      ctaText,
      clickURL
    };
    this.track(externalLinkClickedEventName, properties);
  }

  internalLinkClicked(pageURL: string, ctaText: string, clickURL: string) {
    const properties = {
      pageURL,
      ctaText,
      clickURL
    };
    this.track(internalLinkClickedEventName, properties);
  }

  getValuesFromLookupForCheckboxes(array: any[], lookup: Map<any, ILookup<any>>) {
    const lookupValues = Array.from(lookup.values());
    const values = array?.reduce((selected, shift, index) => {
      if (shift && lookupValues[index]) {
        selected.push(lookupValues[index].name);
      }
      return selected;
    }, []);
    return values.length ? values : undefined;
  }

  convertBoleanIFilterGeneralToString(filter: IFilterGeneral | any) {
    if (filter && filter.hasOwnProperty('value')) {
      return (filter.value === true).toString();
    } else if (typeof filter == 'boolean') {
      return filter.toString();
    }
    return 'false';
  }
}
