import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-crisis-popup',
  templateUrl: './crisis-popup.component.html',
  styleUrls: ['./crisis-popup.component.scss']
})
export class CrisisPopupComponent {
  constructor(public dialogRef: MatDialogRef<CrisisPopupComponent>) {}

  cancel() {
    this.dialogRef.close();
  }
}
