export class Hospital {
  id: string;
  name: string;
  city: string;
  state: string;
  traumaLevel: number;
  pediatricTraumaLevel: number;
  jointCommission: boolean;
  staffedBeds: number;
  teachingFacility: number;
  hospitalType: string; // guid
  emrSystem: string;
  birthsPerYear: number;
  surgeriesPerYear: number;
  erVisitsPerYear: number;
  region: string;
  awards: string;
  addressStreet: string;
  zipCode: string;
  fullAddress: string;
  latitude: number;
  longitude: number;
}
