import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ConfirmationPopup implements OnInit {
  fileType: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationPopup>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.fileType = this.data.fileType;
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
