export class DataDeletionResponse {
  email: string;
  message: string;
  success: boolean;
}

export class DeletionCodeConfirmationResponse extends DataDeletionResponse {
  codeValidated: boolean;
}

export class DeletionCodeConfirmationRequest {
  confirmationCode: string;
}
