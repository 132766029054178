import { Component, Input } from '@angular/core';
import { JobInfoData } from 'src/app/common/models/info-card-data';

@Component({
  selector: 'app-jobs-info-card',
  templateUrl: './jobs-info-card.component.html',
  styleUrls: ['./jobs-info-card.component.scss']
})
export class JobsInfoCardComponent {
  @Input() model: JobInfoData;

  constructor() {}
}
