import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AppUrls } from '../app-urls';
import { Reference } from '../common';
import { ContractType } from '../common/contracts/contract-type';

@Injectable({
  providedIn: 'root'
})
export class NavHelper {
  public sidenav: any;
  public viewMatch: boolean;
  public startingUrl: string;
  public beforeTasksRoute: any;
  public showUserPayDetails = false;
  public taskList: any;

  constructor(private _router: Router) {}

  goToUrl(url: string) {
    this._router.navigateByUrl(url);
  }

  goToExternalUrl(url: string) {
    window.location.href = url;
  }

  goToProfileMenu() {
    this._router.navigate(['/profile-menu']);
  }

  goToVerifyUserEmail() {
    this._router.navigate([`/verify/${AppUrls.VERIFY_USER}`]);
  }

  goToVerifyUserSelection() {
    this._router.navigate([`/verify/${AppUrls.VERIFY_SELECTION}`]);
  }

  goToVerifyUserSMS() {
    this._router.navigate([`/verify/${AppUrls.VERIFY_SMS}`]);
  }

  goToVerifySupport() {
    this._router.navigate([`/verify/${AppUrls.VERIFY_REPORT}`]);
  }

  goToRegistration() {
    this._router.navigate(['/registration']);
  }

  goToCongratulationScreen() {
    this._router.navigate([`${AppUrls.REGISTRATION}/${AppUrls.CONGRATULATIONS}`]);
  }

  goToContactSelect() {
    this._router.navigate([`${AppUrls.CONTACT_SELECT}`]);
  }

  goToLicenses(bypassSync: boolean = false, fromTask: boolean = false) {
    const m = new Map<string, any>();
    if (fromTask) {
      m.set('fromTask', fromTask);
    }
    if (bypassSync) {
      m.set('bypassSync', bypassSync);
    }
    const params = this._buildParams(m);
    this._router.navigateByUrl(`/${AppUrls.LICENSES}${params}`);
  }

  goToCertifications() {
    this._router.navigate([`/${AppUrls.CERTIFICATIONS}`]);
  }

  goToCertificationAdd(complianceRequirementId?: string) {
    const m = new Map<string, any>();
    if (complianceRequirementId) {
      m.set('id', complianceRequirementId);
    }
    const params = this._buildParams(m);
    this._router.navigateByUrl(`/${AppUrls.CERTIFICATIONS}/add${params}`);
  }

  goToCertificationEdit(id: string) {
    this._router.navigate([`${AppUrls.CERTIFICATIONS}/edit/${id}`]);
  }

  goToReferences() {
    this._router.navigate([`/${AppUrls.REFERENCES}`]);
  }

  goToReferencesAdd() {
    this._router.navigate([`/${AppUrls.REFERENCES}/add`]);
  }

  goToReferencesEdit(id: string) {
    this._router.navigateByUrl(`${AppUrls.REFERENCES}/edit/${id}`);
  }

  goToEducation() {
    this._router.navigate([`/${AppUrls.EDUCATION}`]);
  }

  goToEducationAdd() {
    this._router.navigate([`/${AppUrls.EDUCATION}/add`]);
  }

  goToSpecificEducation(id: string) {
    this._router.navigate([`/${AppUrls.EDUCATION}/edit/${id}`]);
  }

  goToSpecificCertification(id: string) {
    this._router.navigate([`/${AppUrls.CERTIFICATIONS}/edit/${id}`]);
  }

  goToSpecificLicense(id: string) {
    this._router.navigate([`/${AppUrls.LICENSES}/edit/${id}`]);
  }

  goToWorkHistory() {
    this._router.navigate([`/${AppUrls.WORK_HISTORY}`]);
  }

  goToWorkHistoryAdd(fromReferences: boolean = false, taskReturn: boolean = false, reference: Reference = null) {
    const m = new Map<string, any>();
    m.set('fromReferences', fromReferences);
    if (taskReturn) {
      m.set('fromTask', true);
    }
    if (reference) {
      m.set('firstName', reference.firstName);
      m.set('lastName', reference.lastName);
      m.set('positionCode', reference.positionCode);
      m.set('email', reference.email);
      m.set('phone', reference.phone);
    }
    const params = this._buildParams(m);
    const url = `/${AppUrls.WORK_HISTORY}/add${params}`;
    this._router.navigateByUrl(url);
  }

  goToWorkHistoryEdit(id: string, taskReturn: boolean = false) {
    const m = new Map<string, any>();
    if (taskReturn) {
      m.set('fromTask', true);
    }

    const params = this._buildParams(m);
    const url = `/${AppUrls.WORK_HISTORY}/edit/${id}${params}`;
    this._router.navigateByUrl(url);
  }

  goToTechnology() {
    this._router.navigate(['/technology']);
  }

  goToTechnologyAdd() {
    this._router.navigate(['/technology/add']);
  }

  goToTechnologyEdit(id: string) {
    this._router.navigate([`/${AppUrls.TECHNOLOGY}/edit/${id}`]);
  }

  goToNursysSetup(fromTask: boolean = false, isVerifying: boolean = false, licenseInfo: any = null, isLastTask: boolean = false, isFromApply: boolean = false) {
    const m = new Map<string, any>();
    if (fromTask) {
      m.set('fromTask', true);
    }
    if (isFromApply) {
      m.set('apply', true);
    }
    if (isVerifying) {
      m.set('isVerifying', true);
    }
    if (licenseInfo) {
      m.set('licenseNum', licenseInfo.licenseNumber);
      m.set('state', licenseInfo.state);
    }
    if (isLastTask) {
      m.set('lastTask', isLastTask);
    }

    const params = this._buildParams(m);
    this._router.navigateByUrl(`${AppUrls.NURSYS_SETUP}${params}`);
  }

  goToManualLicenseAdd(fromTask: boolean = false, licenseNum: string = null, state: string = null, fromNursys: boolean = false, isLastTask: boolean = false) {
    const m = new Map<string, any>();
    if (fromTask) {
      m.set('fromTask', true);
    }
    if (licenseNum) {
      m.set('licenseNum', licenseNum);
    }
    if (state) {
      m.set('state', state);
    }
    // For creating a back 2 when going to Add License from Nursys
    if (fromNursys) {
      m.set('fromNursys', true);
    }
    if (isLastTask) {
      m.set('lastTask', isLastTask);
    }

    const params = this._buildParams(m);
    this._router.navigateByUrl(`/${AppUrls.LICENSES}/add${params}`);
  }

  goToLicenseLandingPage(fromTask: boolean = false, licenseInfo: any = null) {
    const m = new Map<string, any>();
    if (fromTask) {
      m.set('fromTask', true);
    }
    if (licenseInfo) {
      m.set('licenseNum', licenseInfo.licenseNumber);
      m.set('state', licenseInfo.state);
    }

    const params = this._buildParams(m);
    this._router.navigateByUrl(`/${AppUrls.NURSYS_SETUP}${params}`);
  }

  goToExperience() {
    this._router.navigate(['/experience']);
  }

  goToDashBoard(showRecruiterPopup: boolean = false, registered: boolean = false) {
    const m = new Map<string, any>();
    if (showRecruiterPopup) {
      m.set('recruiterPopup', true);
    }

    if (registered) {
      m.set('registered', true);
    }
    const params = this._buildParams(m);
    this._router.navigateByUrl(`${AppUrls.DASHBOARD}${params}`);
  }

  goToBulkUpload() {
    this._router.navigate([`/${AppUrls.DROP_AND_GO}`]);
  }

  goToJobSearch(options?: NavigationExtras) {
    this._router.navigate([`/${AppUrls.JOB_SEARCH}`], options);
  }

  goToJobSearchFilters(filters: string) {
    this._router.navigateByUrl(`${AppUrls.JOB_SEARCH}?${filters}`);
  }

  goToJobSearchSpecific(index: number) {
    this._router.navigateByUrl(`${AppUrls.JOB_SEARCH}?index=${index}`);
  }

  goToJobs() {
    this._router.navigate([AppUrls.JOBS]);
  }

  goToSimilarJobs(id: string, contractType?: ContractType) {
    const queryParams = contractType ? { contractType } : {};
    this._router.navigate([`${AppUrls.SIMILAR_JOBS}/${id}`], {
      queryParams
    });
  }

  goToSubmittals() {
    this._router.navigate([AppUrls.MY_SUBMITTALS]);
  }

  goToJobsSpecific(id: string, recommendedJob = false, completedTask = false, finishApplication = false) {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.navigate([`${AppUrls.JOBS}/${id}`], {
      queryParams: {
        RecommendedJob: recommendedJob,
        CompletedTask: completedTask,
        FinishApplication: finishApplication
      }
    });
  }

  goToJobsSpecificCustomParams(
    id: string,
    params: {
      completedTask?: boolean;
      recommendedJob?: boolean;
      finishApplication?: boolean;
      contractType?: ContractType;
      fromSearch?: boolean;
    } = {},
    isTravel: boolean = true
  ) {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    let queryParams = {};
    if (isTravel) {
      queryParams = {
        RecommendedJob: params.recommendedJob || false,
        CompletedTask: params.completedTask || false,
        FinishApplication: params.finishApplication || false,
        contractType: params.contractType,
        fromSearch: params.fromSearch || false
      };
    }
    this._router.navigate([`${AppUrls.JOBS}/${id}`], {
      queryParams
    });
  }

  goToApplicationReview(id: string, recommendedJob = false) {
    this._router.navigate([`/${AppUrls.APPLICATION_REVIEW}/${id}`], {
      queryParams: { RecommendedJob: recommendedJob }
    });
  }

  goToJobApplication(jobId: string) {
    this._router.navigate([`/${AppUrls.APPLICATION}/${jobId}`]);
  }

  redirectToMarketingJobDetailsPage(jobId) {
    const url = `${environment.marketingBaseUrl}/job?id=${jobId}`;
    this.goToExternalUrl(url);
  }

  redirectToMarketingSignUp(email, firstName, lastName) {
    const queryParams = `?email=${email}&firstname=${firstName}&lastname=${lastName}`;
    const url = `${environment.marketingBaseUrl}/make-your-move-with-on-demand${queryParams}`;
    this.goToExternalUrl(url);
  }

  goToMainTime() {
    this._router.navigate([`/${AppUrls.TIME_TRACKING}`]);
  }

  goToOnAssignment() {
    this._router.navigate([`/${AppUrls.ON_ASSIGNMENT}`]);
  }

  goToExpenseSummary() {
    this._router.navigate([`/${AppUrls.EXPENSE_MANAGEMENT}/expense-summary`]);
  }

  goToTasks() {
    this.taskList = '';
    this.beforeTasksRoute = this._router.url;
    this._router.navigate([`/${AppUrls.TASKS}`]);
  }

  goToPayTasks(showUserPayDetails: boolean = false) {
    this.taskList = 'pay';
    this.showUserPayDetails = showUserPayDetails;
    this.beforeTasksRoute = this._router.url;
    this._router.navigateByUrl(`/${AppUrls.TASKS}?pay=true`);
  }

  goToApplyTasks() {
    this.taskList = 'apply';
    this.beforeTasksRoute = this._router.url;
    this._router.navigateByUrl(`/${AppUrls.TASKS}?apply=true`);
  }

  goToSkills() {
    this._router.navigate([AppUrls.SKILLS]);
  }

  goToSkill(skillId: string) {
    this._router.navigate([`${AppUrls.SKILLS}/${skillId}`]);
  }

  goToMultpleAccounts() {
    this._router.navigate([AppUrls.MULTIPLE_ACCOUNTS_FOUND]);
  }

  goToProfessionalOverview() {
    this._router.navigate([AppUrls.PROFESSIONAL_OVERVIEW]);
  }

  goToAutoOfferConfirmation(id: string) {
    this._router.navigate([`/${AppUrls.JOBS}/auto-offer-confirmation/${id}`]);
  }

  goToJobPreferences(registrationPath = false) {
    registrationPath ? this._router.navigateByUrl(`${AppUrls.JOB_PREFERENCES}?registrationPath=true`) : this._router.navigate([AppUrls.JOB_PREFERENCES]);
  }

  goToNewJobPreferences(registrationPath = false) {
    registrationPath ? this._router.navigateByUrl(`${AppUrls.JOB_PREFERENCES}?registrationPath=true`) : this._router.navigate([AppUrls.PREFERENCES, AppUrls.JOB_PREFERENCES]);
  }

  goToContactPreferences() {
    this._router.navigate([AppUrls.PREFERENCES, AppUrls.CONTACT_PREFERENCES]);
  }

  goToJobQuestionnaire() {
    this._router.navigate([AppUrls.QUESTIONNAIRE]);
  }

  goToInternalLogin(): void {
    this._router.navigate([AppUrls.INTERNAL_LOGIN]);
  }

  goToLoginError(): void {
    this._router.navigate([AppUrls.LOGIN_ERROR]);
  }

  public closeNav() {
    if (this.viewMatch) {
      this.sidenav.close();
    }
  }

  public openNav() {
    this.sidenav.open();
  }

  async refreshAddCertification() {
    await this._router
      .navigate([`/`], { skipLocationChange: true })
      .then(async () => {
        await this._router.navigate([`/${AppUrls.CERTIFICATIONS}/add`], {
          skipLocationChange: true
        });
      })
      .catch(() => console.log('Error during navigation.'));
  }

  async refreshAddEducation() {
    await this._router.navigate([`/`], { skipLocationChange: true });
    this.goToEducationAdd();
  }

  public _buildParams(map: Map<string, any>): string {
    if (map === undefined || map.size === 0) {
      return '';
    }

    const paramList = [];
    Array.from(map.keys()).forEach(item => {
      paramList.push(`${item}=${map.get(item)}`);
    });

    return '?' + paramList.join('&');
  }

  goBackWhenTasksCompleted() {
    window.location.href = this.beforeTasksRoute;
  }

  goBackToTasks() {
    switch (this.taskList) {
      case 'pay':
        this.goToPayTasks();
        break;

      case 'apply':
        this.goToApplyTasks();
        break;

      default:
        this.goToTasks();
        break;
    }
  }

  goToArrivals(): void {
    this._router.navigate([AppUrls.ARRIVALS]);
  }

  goToArrivalActivities(): void {
    this._router.navigate([AppUrls.ARRIVALS_ACTIVITIES]);
  }

  goToArrivalCar(): void {
    this._router.navigate([AppUrls.ARRIVALS_CAR]);
  }

  goToArrivalFlights(): void {
    this._router.navigate([AppUrls.ARRIVALS_FLIGHTS]);
  }

  goToArrivalHotels(): void {
    this._router.navigate([AppUrls.ARRIVALS_HOTELS]);
  }

  goToArrivalPhone(): void {
    this._router.navigate([AppUrls.ARRIVALS_PHONE]);
  }
}
