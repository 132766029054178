import { TravelExpenses } from './travel-expense';

export interface Arrivals {
  id: string;
  city: string;
  state: string;
  confirmedDeploymentDate: string;
  arrivalContractor: string;
  clearedToStart: string;
  candidatePhoneNumber: string;
  arrivalDetails: string;
  arrivalContractorId: string;
  welcomeGuideUrl: string;
  actualArrivalDate: string;
  phonePlanType: string;
  phonePlanNumber: string;
  phonePlanStartDate: string;
  travelExpenses: TravelExpenses;
  hasArrivalActivities: boolean;
}
