import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, retry, switchMap, tap } from 'rxjs/operators';
import { ApplicationReviewModel, IJob, ILookups, PortalStatuses, SimilarJobByIdSearchModel, SimilarJobSearchModel, Submittal, TaskCompletionReturnObject } from 'src/app/common';
import { Recommendation, RecommendedJobsResult } from 'src/app/common/contracts/cupid-models';
import { getDateInFutureWithMinutes } from 'src/app/common/functions/date-manipulations';
import { JobDataModel } from 'src/app/common/models/job-data-model';
import { JobPreferenceReturn, JobPreferencesIN } from 'src/app/common/models/job-preference';
import { HCINPortalAPIService, NursePortalApi } from 'src/app/services';
import { UsersApiService } from 'src/app/services/users-api.service';
import { EUserContextActions, SetUserJobNotificationsActive } from '../userContext/userContext.actions';
import {
  ApplyToSpecificJob,
  ApplyToSpecificJobError,
  ApplyToSpecificJobSuccess,
  ArchiveSubmittal,
  ArchiveSubmittalError,
  ArchiveSubmittalSuccess,
  CupidEventRecord,
  EJobsActions,
  GetApplicationError,
  GetApplicationSuccess,
  GetAvailableJobs,
  GetAvailableJobsSuccess,
  GetHasAutoOfferAssignmentsError,
  GetHasAutoOfferAssignmentsSuccess,
  GetJobPreferencesError,
  GetJobPreferencesINError,
  GetJobPreferencesINSuccess,
  GetJobPreferencesSuccess,
  GetJobSubmittals,
  GetJobSubmittalsError,
  GetJobSubmittalsINError,
  GetJobSubmittalsINSuccess,
  GetJobSubmittalsSuccess,
  GetLocationsByName,
  GetLocationsByNameError,
  GetLocationsByNameSuccess,
  GetMapUrlForFacility,
  GetMapUrlForFacilityError,
  GetMapUrlForFacilitySuccess,
  GetRecommendedJobsByPreference,
  GetRecommendedJobsByPreferenceError,
  GetRecommendedJobsByPreferenceSuccess,
  GetSavedJobsError,
  GetSavedJobsSuccess,
  GetSimilarJobs,
  GetSimilarJobsByFacilityId,
  GetSimilarJobsByFacilityIdError,
  GetSimilarJobsByFacilityIdSuccess,
  GetSimilarJobsByJobId,
  GetSimilarJobsByJobIdError,
  GetSimilarJobsByJobIdSuccess,
  GetSimilarJobsError,
  GetSimilarJobsSuccess,
  GetSimilarJobsSuccessMapped,
  GetSpecificAssignment,
  GetSpecificAssignmentError,
  GetSpecificAssignmentSuccess,
  GetSpecificJob,
  GetSpecificJobError,
  GetSpecificJobSuccess,
  JobsActions,
  ResetApplyToSpecificJob,
  ResetUpdateApplication,
  ResetUpdateProfileReviewResult,
  SetJobPreferences,
  SetJobPreferencesError,
  SetJobPreferencesSuccess,
  SetSavedAvailableJob,
  SetSavedAvailableJobSuccess,
  SetSavedJob,
  SetSavedJobError,
  SetSavedJobSuccess,
  SetSavedRecommendedJob,
  SetSavedRecommendedJobSuccess,
  SetSavedSimilarJob,
  SetSavedSimilarJobSuccess,
  UpdateApplication,
  UpdateApplicationError,
  UpdateApplicationSuccess,
  UpdateJobNotifications,
  UpdateJobNotificationsError,
  UpdateProfileReviewData,
  UpdateProfileReviewDataError,
  UpdateProfileReviewDataSuccess,
  UpsertJobPreferencesIN,
  UpsertJobPreferencesINError,
  UpsertJobPreferencesINSuccess
} from 'src/app/store/jobs/jobs.actions';
import { IAppState } from '../app/app.state';
import { selectLookups } from 'src/app/store/lookups/lookups.selectors';
import { selectContractType, selectJobSubmittals, selectJobSubmittalsExpirationDate, selectJobSubmittalsExpired } from './jobs.selectors';
import { NotificationService } from 'hc-design-system-lib';
import { ContractType } from 'src/app/common/contracts/contract-type';
import { CandidateJobsApiService } from 'src/app/services/candidate-jobs-api.service';
import { SearchFacilitiesByProximity } from '../facilities/facilities.actions';
import { FacilityProximitySearch } from 'src/app/common/contracts/facility-proximity-search';
import { SubmittalsService } from '../../services/submittals.service';
import { selectCanSeeInternational } from '../userContext/userContext.selectors';
import { jobPreferenceINToastMessages } from 'src/app/modules/preferences/job-preferences/pages/hcin-job-preferences/hcin-job-preference-constants';

export function mapRecommendationToIJob(_lookup: ILookups, recommendation: Recommendation) {
  const profession = Array.from(_lookup.professionLookup.values()).find(p => p.shortName === recommendation?.recordAttributes?.profession).id;
  const specialty = (
    Array.from(_lookup.specialtyLookup.values()).find(p => p.name === recommendation?.recordAttributes?.specialty) ||
    Array.from(_lookup.specialtyLookup.values()).find(p => p.shortName === recommendation?.recordAttributes?.specialty)
  ).id;
  const state = Array.from(_lookup.stateLookup.values()).find(p => p.code === recommendation?.recordAttributes?.hospitalState).id;
  const showPayLookup = Array.from(_lookup.yesNoLookup.values());
  let showPay = showPayLookup.find(p => p.name === 'No').id as unknown as number;

  if (recommendation?.recordAttributes?.showPay && recommendation.recordAttributes.showPay === 'Yes') {
    showPay = showPayLookup.find(p => p.name === 'Yes').id as unknown as number;
  }

  return {
    id: recommendation.jobId,
    profession: profession,
    specialty: specialty,
    hospital: recommendation.recordAttributes?.hospitalName,
    hospitalId: recommendation.recordAttributes?.hospitalId,
    city: recommendation.recordAttributes?.hospitalCity,
    state: state,
    estGrossPay: +recommendation.recordAttributes?.weeklyGross,
    estTravelGrossPay: +recommendation.recordAttributes?.travelPayWeeklyGross,
    estLocalGrossPay: +recommendation.recordAttributes?.localPayWeeklyGross,
    startDate: recommendation.recordAttributes?.startDate,
    shift: recommendation.recordAttributes?.shift as unknown as number,
    assignmentLength: recommendation.recordAttributes?.assignmentLength as unknown as number,
    showPay: showPay,
    saved: recommendation.savedStatus === 'true'
  } as IJob;
}

@Injectable({
  providedIn: 'root'
})
export class JobsEffects {
  constructor(
    private actions$: Actions,
    private _api: NursePortalApi,
    private _usersApi: UsersApiService,
    private _store: Store<IAppState>,
    private _notificationService: NotificationService,
    private _candidateJobsApi: CandidateJobsApiService,
    private _submittalService: SubmittalsService,
    private _hcinApi: HCINPortalAPIService
  ) {}

  setJobPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.SetJobPreferences),
      exhaustMap((action: SetJobPreferences) =>
        this._usersApi.postJobPreferences(action.payload).pipe(
          switchMap((jobPreferenceData: JobPreferenceReturn) => [new GetRecommendedJobsByPreference(), new SetJobPreferencesSuccess(jobPreferenceData)]),
          catchError((error: Error) => of(new SetJobPreferencesError(error)))
        )
      )
    );
  });

  getJobPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.GetJobPreferences),
      concatLatestFrom(() => this._store.select(selectCanSeeInternational)),
      exhaustMap(([action, isIN]) => {
        if (isIN) {
          return this._hcinApi.getJobPreferences().pipe(
            map((jobPreferenceData: JobPreferencesIN) => new GetJobPreferencesINSuccess(jobPreferenceData)),
            catchError((error: Error) => of(new GetJobPreferencesINError(error)))
          );
        } else {
          return this._usersApi.getPreferences().pipe(
            map((jobPreferenceData: JobPreferenceReturn) => new GetJobPreferencesSuccess(jobPreferenceData)),
            catchError((error: Error) => of(new GetJobPreferencesError(error)))
          );
        }
      })
    );
  });

  getJobs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.GetAvailableJobs),
      map((action: GetAvailableJobs) => action.model),
      concatLatestFrom(() => this._store.select(selectContractType)),
      concatMap(([model, contractType]) =>
        this._candidateJobsApi.searchJobs(model).pipe(
          map((data: JobDataModel) => {
            const response = {
              ...data,
              jobs: data.jobs.map(j => ({
                ...j,
                contractType: contractType || ContractType.Travel
              }))
            };
            return new GetAvailableJobsSuccess(response, model.reload, data?.totalCount);
          }),
          catchError((error: Error) => of(new GetJobPreferencesError(error)))
        )
      )
    );
  });

  getSimilarJobs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.GetSimilarJobs),
      map((action: GetSimilarJobs) => action.input),
      exhaustMap((model: SimilarJobSearchModel) =>
        this._api.getSimilarJobs(model).pipe(
          map((similarJobs: { results: JobDataModel }) => new GetSimilarJobsSuccess(similarJobs.results)),
          catchError((error: Error) => of(new GetSimilarJobsError(error)))
        )
      )
    );
  });

  getSimilarJobsSuccessAddContractTypeProperty$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.GetSimilarJobsSuccess),
      map((action: GetSimilarJobsSuccess) => action.payload),
      concatLatestFrom(() => this._store.select(selectContractType)),
      switchMap(([jobsPayload, contractType]: [JobDataModel, ContractType]) => {
        const newJobsPayload = new JobDataModel();
        newJobsPayload.totalCount = jobsPayload.totalCount;
        newJobsPayload.jobs = jobsPayload.jobs.map(job => ({
          ...job,
          contractType: contractType || ContractType.Travel
        }));
        return of(new GetSimilarJobsSuccessMapped(newJobsPayload));
      })
    );
  });

  getSimilarJobsById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.GetSimilarJobsByJobId),
      retry(1),
      map((action: GetSimilarJobsByJobId) => action.input),
      exhaustMap((model: SimilarJobByIdSearchModel) =>
        this._api.getSimilarJobsByJobId(model.id).pipe(
          map((similarJobsById: any) => new GetSimilarJobsByJobIdSuccess(similarJobsById)),
          catchError((error: Error) => of(new GetSimilarJobsByJobIdError(error)))
        )
      )
    );
  });

  getRecommendedJobsByPreference$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.GetRecommendedJobsByPreference, EUserContextActions.UpdateSummarySuccess),
      switchMap(() =>
        combineLatest([this._api.getCupidRecommendedJobsByPreference(), this._store.select(selectLookups)]).pipe(
          map(([jobRecommendationData, lookups]: [RecommendedJobsResult, ILookups]) => [
            jobRecommendationData.rowsReturned === 0 ? [] : jobRecommendationData.recommendations,
            lookups
          ]),
          map(([jobRecommendationsRecommendation, lookups]: [Recommendation[], ILookups]) => {
            return jobRecommendationsRecommendation.map((recommendation: Recommendation) => {
              return mapRecommendationToIJob(lookups, recommendation);
            });
          }),
          map((jobRecommendationsJobs: IJob[]) => new GetRecommendedJobsByPreferenceSuccess(jobRecommendationsJobs)),
          catchError((error: Error) => of(new GetRecommendedJobsByPreferenceError(error)))
        )
      )
    );
  });

  getJobSubmittals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.GetJobSubmittals),
      map((action: GetJobSubmittals) => action.forceUpdateCache),
      concatLatestFrom(() => [this._store.select(selectJobSubmittals), this._store.select(selectJobSubmittalsExpired), this._store.select(selectJobSubmittalsExpirationDate)]),
      switchMap(([forceUpdateCache, submittals, isExpired, expiration]: [boolean, Submittal[], boolean, Date]) => {
        if (!forceUpdateCache && !isExpired) {
          return of(new GetJobSubmittalsSuccess({ submittals, expiration }));
        }
        return this._api.getMySubmittals().pipe(
          switchMap(response => {
            const expirationDate = getDateInFutureWithMinutes(10);
            const payload = {
              submittals: response.map(submittal => {
                return {
                  ...submittal,
                  portalStatus: this._submittalService.isNoLongerAvailableAndSubmitted(submittal) ? PortalStatuses.PendingReview : submittal.portalStatus,
                  job: {
                    ...submittal.job,
                    estGrossPay: this._submittalService.isNoLongerAvailableAndSubmitted(submittal) ? undefined : submittal.job.estGrossPay,
                    localEstWeeklyGross: this._submittalService.isNoLongerAvailableAndSubmitted(submittal) ? undefined : submittal.job.localEstWeeklyGross
                  }
                };
              }),
              expiration: expirationDate
            };
            return of(new GetJobSubmittalsSuccess(payload));
          }),
          catchError((error: Error) => of(new GetJobSubmittalsError(error)))
        );
      })
    );
  });

  getJobSubmittalsIN$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.GetJobSubmittalsIN),
      concatLatestFrom(() => this._store.select(selectCanSeeInternational)),
      filter(([action, canSeeIN]) => canSeeIN),
      switchMap(([action, canSeeIN]) => {
        return this._hcinApi.getAssignments().pipe(
          switchMap(response => {
            return of(new GetJobSubmittalsINSuccess({ submittals: response }));
          }),
          catchError((error: Error) => of(new GetJobSubmittalsINError(error)))
        );
      })
    );
  });

  archiveSubmittal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.ArchiveSubmittal),
      map((action: ArchiveSubmittal) => action.payload),
      exhaustMap(action =>
        this._api.archiveSubmittal(action.id).pipe(
          map(() => new ArchiveSubmittalSuccess({ id: action.id })),
          catchError((error: Error) => of(new ArchiveSubmittalError(error)))
        )
      )
    );
  });

  archiveSubmittalSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.ArchiveSubmittalSuccess),
      map(() => new GetJobSubmittals())
    );
  });

  getSavedJobs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.GetSavedJobs),
      exhaustMap(() =>
        this._api.getSavedJobs().pipe(
          map((data: JobDataModel) => new GetSavedJobsSuccess(data)),
          catchError((error: Error) => of(new GetSavedJobsError(error)))
        )
      )
    );
  });

  setSavedJob$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(
        EJobsActions.SetSavedJob,
        EJobsActions.SetSavedRecommendedJobSuccess,
        EJobsActions.SetSavedSimilarJobSuccess,
        EJobsActions.SetSavedAvailableJobSuccess
      ),
      map((action: SetSavedJob) => action.payload),

      concatMap(action =>
        this._api.setSavedJob(action.job.id, action.saveValue, action.contractType).pipe(
          map(
            (value: number) =>
              new SetSavedJobSuccess({
                value,
                job: action.job,
                saveValue: action.saveValue,
                contractType: action.contractType
              })
          ),
          catchError((error: Error) =>
            of(
              new SetSavedJobError({
                error,
                saveData: {
                  job: action.job,
                  saveValue: action.saveValue,
                  contractType: action.contractType
                }
              })
            )
          )
        )
      )
    );
  });

  setSavedRecommendedJob$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.SetSavedRecommendedJob),
      map((action: SetSavedRecommendedJob) => action.payload),

      concatMap(action => {
        const payload = {
          job: action.job,
          saveValue: action.saveValue,
          contractType: action.contractType
        };

        return of(new SetSavedRecommendedJobSuccess(payload));
      })
    );
  });

  setSavedSimilarJob$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.SetSavedSimilarJob),
      map((action: SetSavedSimilarJob) => action.payload),

      concatMap(action => {
        const payload = {
          job: action.job,
          saveValue: action.saveValue,
          contractType: action.job.contractType
        };

        return of(new SetSavedSimilarJobSuccess(payload));
      })
    );
  });

  setSavedJobResult$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.SetSavedAvailableJob),
      map((action: SetSavedAvailableJob) => action.payload),

      concatMap(action => {
        const payload = {
          job: action.job,
          saveValue: action.saveValue,
          contractType: action.contractType
        };

        return of(new SetSavedAvailableJobSuccess(payload));
      })
    );
  });

  getLocationsByName$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.GetLocationsByName),
      exhaustMap((action: GetLocationsByName) =>
        this._api.getLocationsByName(action.payload).pipe(
          map((locations: any[]) => new GetLocationsByNameSuccess(locations)),
          catchError((error: Error) => of(new GetLocationsByNameError(error)))
        )
      )
    );
  });

  getSpecificJob$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.GetSpecificJob),
      map((action: GetSpecificJob) => action.jobId),
      switchMap(id =>
        this._api.getJob(id).pipe(
          switchMap(job => of(new GetSpecificJobSuccess(job))),
          catchError((error: Error) => of(new GetSpecificJobError(error)))
        )
      )
    );
  });

  getSpecificAssignment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.GetSpecificAssignment),
      map((action: GetSpecificAssignment) => action.assignmentId),
      switchMap(id =>
        this._hcinApi.GetAssignmentById(id).pipe(
          switchMap(assn => of(new GetSpecificAssignmentSuccess(assn))),
          catchError((error: Error) => of(new GetSpecificAssignmentError(error)))
        )
      )
    );
  });

  getSimilarJobsForFacility$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.GetSimilarJobsByFacilityId),
      map((action: GetSimilarJobsByFacilityId) => action.facilityId),
      switchMap(id =>
        this._api.getJobsForHospital(id).pipe(
          switchMap(jobs => of(new GetSimilarJobsByFacilityIdSuccess(jobs))),
          catchError((error: Error) => of(new GetSimilarJobsByFacilityIdError(error)))
        )
      )
    );
  });

  getMapUrlForFacility$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.GetMapUrlForFacility),
      map((action: GetMapUrlForFacility) => action.addr),
      switchMap(addr =>
        this._api.getHospitalMapUrl(addr).pipe(
          switchMap(url => of(new GetMapUrlForFacilitySuccess(url))),
          catchError((error: Error) => of(new GetMapUrlForFacilityError(error)))
        )
      )
    );
  });

  updateJobNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.UpdateJobNotifications),
      exhaustMap((action: UpdateJobNotifications) =>
        this._api.updateJobNotifications(action.payload.merlinId, action.payload.value).pipe(
          map(() => new SetUserJobNotificationsActive(action.payload.value)),
          catchError((error: Error) => of(new UpdateJobNotificationsError(error)))
        )
      )
    );
  });

  getHasAutoOfferAssignments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.GetHasAutoOfferAssignments),
      exhaustMap(() =>
        this._api.getHasAutoOfferAssignments().pipe(
          map((hasAutoOffers: boolean) => new GetHasAutoOfferAssignmentsSuccess(hasAutoOffers)),
          catchError((error: Error) => of(new GetHasAutoOfferAssignmentsError(error)))
        )
      )
    );
  });

  cupidEventRecord$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<JobsActions>(EJobsActions.CupidEventRecord),
        tap((action: CupidEventRecord) => {
          this._api.addCupidEventRecordJobSaved(action.jobId);
        })
      );
    },
    { dispatch: false }
  );

  applyToSpecificJob$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.ApplyToSpecificJob),
      exhaustMap((action: ApplyToSpecificJob) =>
        this._api.apply(action.specificJob.id, action.rto, action.contractType, action.hasCompletedTasks, action.isRecommended).pipe(
          retry(1),
          map((response: any) => new ApplyToSpecificJobSuccess(response)),
          catchError((error: Error) => of(new ApplyToSpecificJobError(error)))
        )
      )
    );
  });

  applyToSpecificJobErrorNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<JobsActions>(EJobsActions.ApplyToSpecificJobError, EJobsActions.UpdateApplicationError),
        tap(() => this._notificationService.showNotification(`We were unable to save your application. Please try again.`, 'error'))
      );
    },
    { dispatch: false }
  );

  updateApplication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.UpdateApplication),
      exhaustMap((action: UpdateApplication) =>
        this._api.updateApplication(action.specificJob.id, action.rto, action.hasCompletedTasks).pipe(
          map((response: number) => new UpdateApplicationSuccess(response)),
          catchError((error: Error) => of(new UpdateApplicationError(error)))
        )
      )
    );
  });

  resetUpdateApplicationOnSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.UpdateApplicationSuccess),
      map(() => new ResetUpdateApplication())
    );
  });

  resetApplyToJobOnSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.ApplyToSpecificJobSuccess),
      map(() => new ResetApplyToSpecificJob())
    );
  });

  getApplication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.GetApplication),
      exhaustMap(() =>
        this._api.getApplicationReview().pipe(
          map((response: ApplicationReviewModel) => new GetApplicationSuccess(response)),
          catchError((error: Error) => of(new GetApplicationError(error)))
        )
      )
    );
  });

  getSubmittalOnApplySuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.ApplyToSpecificJobSuccess),
      map(() => new GetJobSubmittals())
    );
  });

  updateProfileReviewData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.UpdateProfileReviewData),
      exhaustMap((action: UpdateProfileReviewData) =>
        this._api.updateProfileReviewData(action.model).pipe(
          map((response: TaskCompletionReturnObject) => new UpdateProfileReviewDataSuccess(response)),
          catchError((error: Error) => of(new UpdateProfileReviewDataError(error)))
        )
      )
    );
  });

  resetUpdateProfileReviewDataOnSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.UpdateProfileReviewDataSuccess),
      map(() => new ResetUpdateProfileReviewResult())
    );
  });

  triggerFacilitySearch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.GetAvailableJobs),
      map((action: GetAvailableJobs) => {
        const parameters: FacilityProximitySearch = {
          location: action.model?.locations?.length ? action.model.locations[0] : undefined,
          facility: action.model?.facilities?.length ? action.model.facilities[0].facilityId : undefined
        };
        return parameters;
      }),
      map((parameters: FacilityProximitySearch) => {
        return new SearchFacilitiesByProximity(parameters);
      })
    );
  });

  upsertJobPreferencesIN$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<JobsActions>(EJobsActions.UpsertJobPreferencesIN),
      exhaustMap((action: UpsertJobPreferencesIN) =>
        this._hcinApi.upsertJobPreferences(action.payload).pipe(
          map((id: string) => new UpsertJobPreferencesINSuccess(id)),
          catchError((error: Error) => of(new UpsertJobPreferencesINError(error)))
        )
      )
    );
  });

  upsertJobPreferencesINSuccessNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<JobsActions>(EJobsActions.UpsertJobPreferencesINSuccess),
        tap(() => this._notificationService.showNotification(jobPreferenceINToastMessages.success, 'success'))
      );
    },
    { dispatch: false }
  );

  upsertJobPreferencesINErrorNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<JobsActions>(EJobsActions.UpsertJobPreferencesINError),
        tap(() => this._notificationService.showNotification(jobPreferenceINToastMessages.error, 'error'))
      );
    },
    { dispatch: false }
  );
}
