import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { IAppState } from 'src/app/store/app/app.state';
import { selectHasArrivals, selectArrivalsLoading, selectHasFetchedArrivals } from 'src/app/store/arrivals/arrivals.selectors';
import { AppUrls } from '../app-urls';
import { selectIsSpecificFlagOn } from '../store/flags/flags.selectors';
import { featureFlagNames } from '../services/feature-flags.service';

@Injectable()
export class ArrivalsGuard implements CanActivate {
  constructor(
    private readonly _router: Router,
    private readonly _store: Store<IAppState>
  ) {}

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | UrlTree {
    const hasArrivals$: Observable<boolean> = this._store.select(selectHasArrivals);
    const arrivalsFetched$: Observable<boolean> = this._store.select(selectHasFetchedArrivals);
    const arrivalsLoading$: Observable<boolean> = this._store.select(selectArrivalsLoading);
    const arrivalsFeatureFlag$: Observable<boolean> = this._store.select(selectIsSpecificFlagOn(featureFlagNames.arrivalsIN));

    return combineLatest([hasArrivals$, arrivalsLoading$, arrivalsFetched$, arrivalsFeatureFlag$]).pipe(
      skipWhile(([_hasArrivals, arrivalsLoading, arrivalsFetched, _arrivalsFeatureFlag]: [boolean, boolean, boolean, boolean]) => arrivalsLoading || !arrivalsFetched),
      map(([hasArrivals, _arrivalsLoading, _arrivalsFetched, arrivalsFeatureFlag]: [boolean, boolean, boolean, boolean]) => {
        if (!hasArrivals || !arrivalsFeatureFlag) {
          return this._router.parseUrl(`/${AppUrls.DASHBOARD}`);
        }
        return hasArrivals && arrivalsFeatureFlag;
      })
    );
  }
}
