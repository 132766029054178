<div [formGroup]="form">
  <mat-form-field>
    <input type="text" [formControlName]="controlName" [placeholder]="placeholder" matInput [matAutocomplete]="auto3" [required]="required" />
    <mat-autocomplete autoActiveFirstOption #auto3="matAutocomplete" [displayWith]="displayFunction" (optionSelected)="autoOptionSelected($event)">
      <mat-option *ngFor="let item of filteredList | async" [value]="item">
        {{ getName(item) }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="matErrorText !== ''">{{ matErrorText }}</mat-error>
    <button id="autocompleteClear" *ngIf="clearButton" matSuffix mat-icon-button (click)="clear()">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>
</div>
