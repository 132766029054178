import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { IFeatureFlag } from 'src/app/common/contracts/feature-flag';
import { getDateInFutureWithMinutes } from 'src/app/common/functions/date-manipulations';
import { FeatureFlagService } from 'src/app/services/feature-flags.service';
import { IAppState } from '../app/app.state';
import { EFlagsActions, FlagsActions, GetFlags, GetFlagsError, GetFlagsSuccess, GetVariant, GetVariantError, GetVariantSuccess, InitializeAndGetFlags } from './flags.actions';
import { selectFlags, selectFlagsExpirationDate, selectFlagsExpired } from './flags.selectors';

@Injectable({
  providedIn: 'root'
})
export class FlagsEffects {
  constructor(
    private actions$: Actions,
    private _featureFlagService: FeatureFlagService,
    private _store: Store<IAppState>
  ) {}

  initializeAndGetFlags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<FlagsActions>(EFlagsActions.InitializeAndGetFlags),
      exhaustMap((_: InitializeAndGetFlags) =>
        from(this._featureFlagService.initializeAndGetFeatureFlags()).pipe(
          map((data: Map<string, IFeatureFlag>) => {
            const expirationDate = getDateInFutureWithMinutes(5);
            const result = {
              flags: data,
              expiration: expirationDate
            };
            return new GetFlagsSuccess(result);
          }),
          catchError((error: Error) => of(new GetFlagsError(error)))
        )
      )
    );
  });

  getFlags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<FlagsActions>(EFlagsActions.GetFlags),
      map((action: GetFlags) => action.forceUpdateCache),
      concatLatestFrom(() => [this._store.select(selectFlags), this._store.select(selectFlagsExpired), this._store.select(selectFlagsExpirationDate)]),
      switchMap(([forceUpdateCache, flags, isExpired, expiration]: [boolean, Map<string, IFeatureFlag>, boolean, Date]) => {
        if (!forceUpdateCache && !isExpired) {
          return of(new GetFlagsSuccess({ flags, expiration }));
        }
        return this._featureFlagService.fetchAllFlagValues().pipe(
          switchMap(response => {
            const expirationDate = getDateInFutureWithMinutes(5);
            const result = {
              flags: response,
              expiration: expirationDate
            };
            return of(new GetFlagsSuccess(result));
          }),
          catchError((error: Error) => of(new GetFlagsError(error)))
        );
      })
    );
  });

  getVariant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<FlagsActions>(EFlagsActions.GetVariant),
      map((action: GetVariant) => action.flagName),
      switchMap((flagName: string) => {
        return this._featureFlagService.fetchVariant(flagName).pipe(
          switchMap(response => {
            const result = {
              variant: response
            };
            return of(new GetVariantSuccess(result));
          }),
          catchError((error: Error) => of(new GetVariantError(error)))
        );
      })
    );
  });
}
