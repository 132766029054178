import { Observable, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { FormHeaderService } from '../services';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { GetDashboardInfo } from 'src/app/store/dashboard/dashboard.actions';

export abstract class BaseForm<T> {
  isSaving = false;
  form: UntypedFormGroup;
  protected formSubmissionSubscription: Subscription;

  constructor(
    protected _location: Location,
    protected _formHeaderService: FormHeaderService,
    protected _store: Store<IAppState>
  ) {}
  abstract _createForm();
  abstract onSave(): Observable<T> | null;
  save() {
    if (this.form != null) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      if (this.form.valid) {
        const obs = this.onSave();
        if (obs != null) {
          this.isSaving = true;
          this.form.disable();
          this.subscribe(obs);
          this._formHeaderService.setDisableActions(true);
        }
      } else {
        event.preventDefault();
        document.querySelector('mat-form-field.ng-invalid')?.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      const obs = this.onSave();
      if (obs != null) {
        this.isSaving = true;
        this.subscribe(obs);
        this._formHeaderService.setDisableActions(true);
      }
    }
  }

  subscribe(obs: Observable<any>) {
    this.formSubmissionSubscription = obs.subscribe(
      o => {
        this.onSuccess();
      },
      (error: HttpErrorResponse) => {
        if (error.status === 400) {
          this.onValidation(error);
        } else {
          this.onError(error);
        }
      }
    );
  }

  onSuccess() {
    this.formSubmissionSubscription?.unsubscribe();
    this._store.dispatch(new GetDashboardInfo());
    this._location.back();
  }

  onValidation(error: HttpErrorResponse) {
    this.isSaving = false;
    if (this.form != null) {
      this.form.enable();
    }
    this._formHeaderService.setDisableActions(false);
  }
  onError(error: HttpErrorResponse) {
    this.isSaving = false;
    if (this.form != null) {
      this.form.enable();
    }
    this._formHeaderService.setDisableActions(false);
  }
}
