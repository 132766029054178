import { Component, EventEmitter, Inject } from '@angular/core';
import { NurseModel } from 'src/app/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-expiring-popup',
  templateUrl: './expiring.popup.html',
  styleUrls: ['./expiring.popup.scss']
})
export class ExpiringPopupComponent {
  onCancel = new EventEmitter();
  useOldData = true;
  url: string;
  message: string;
  nurse: NurseModel;
  businessUnit: any;

  constructor(
    public dialogRef: MatDialogRef<ExpiringPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.message = data.message;
  }

  existing() {
    this.dialogRef.close(this.useOldData);
  }

  cancel() {
    this.useOldData = false;
    this.dialogRef.close(this.useOldData);
  }
}
