export interface IDataState<T = any> {
  data: T;
  loading: boolean;
  error: Error;
  expirationDate?: Date;
  requestId?: string;
  previousData?: T;
  hasFetched?: boolean;
}

// This function allows for setting the initial value for the data field
// If the function is called without a value data will be set as null
export function initializeData<T = any>(initialData: T = null): IDataState<T> {
  return {
    data: initialData,
    loading: false,
    error: null,
    hasFetched: false
  };
}
