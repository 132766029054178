import { initializeData, IDataState } from 'src/app/store/app/app.models';
import { Arrivals } from '../../common/models/arrivals';

export interface IArrivalsState {
  arrivals: IDataState<Arrivals>;
}

export const initialArrivalsState: IArrivalsState = {
  arrivals: initializeData()
};
