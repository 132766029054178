import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import {
  AssignmentSources,
  AssignmentStageConstants,
  IFilterGeneral,
  IJob,
  ILookup,
  JobFilterApiBase,
  LocationRadiusGroupFilter,
  PortalStatuses,
  Submittal,
  WithdrawnReasonsSubstageArray
} from 'src/app/common';
import { Interview } from 'src/app/common/models/interview-model';
import { RemoveSubmittalPopupComponent } from 'src/app/components';
import { InterviewPopupComponent } from 'src/app/components/shared/interview-popup/interview-popup.component';
import { StatusInfoPopupComponent } from 'src/app/components/shared/status-info-popup/status-info-popup.component';
import { NavHelper } from 'src/app/services';
import { JobAreaContext } from 'src/app/services/job-area-context.service';
import { CardIconModel, CardLinkModel, CardModel, CardComponentConfig } from '../card-template/card-template.component';
import { selectSpecialtyLookup, selectProfessionLookup, selectYesNoOnlyLookup } from 'src/app/store/lookups/lookups.selectors';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { Subscription, Observable, combineLatest } from 'rxjs';

@Component({
  selector: 'app-submittal-card',
  templateUrl: './submittal-card.component.html',
  styleUrls: ['./submittal-card.component.scss']
})
export class SubmittalCardComponent implements OnInit, OnDestroy {
  @Input() submittal: Submittal;
  @Input() config: CardComponentConfig;
  @Input() completedTasks: boolean;
  @Output() archived = new EventEmitter<string>();
  @Output() removeConfirm = new EventEmitter<any>();
  @Output() outputRemoveAll = new EventEmitter<any>();

  cardModel: CardModel = new CardModel();
  submittalConfig: CardComponentConfig = new CardComponentConfig();
  jobName: string;
  location: string;
  isPending = false;
  showSimilarJobsButton: boolean;
  canBeWithdrawn = false;
  showInterviewButton = false;
  showArchiveButton = false;
  buttonsDisabled = false;
  specialtyLookup: Map<string, ILookup<string>>;
  professionLookup: Map<string, ILookup<string>>;
  yesNoOnlyLookup: Map<string, ILookup<string>>;
  portalStatus: number;

  lookupsSubscription: Subscription;

  specialtyLookup$: Observable<Map<string, ILookup<string>>> = this._store.select(selectSpecialtyLookup);

  professionLookup$: Observable<Map<string, ILookup<string>>> = this._store.select(selectProfessionLookup);

  yesNoOnlyLookup$: Observable<Map<string, ILookup<string>>> = this._store.select(selectYesNoOnlyLookup);

  constructor(
    private _dialog: MatDialog,
    public _ctx: JobAreaContext,
    private _nav: NavHelper,
    private _store: Store<IAppState>
  ) {}

  ngOnInit(): void {
    this.portalStatus = this.getPortalStatusDisplay(this.submittal);
    this.submittalConfig = this.config;
    this.addLookupsSubscription();
  }

  ngOnDestroy(): void {
    this.lookupsSubscription?.unsubscribe();
  }

  addLookupsSubscription(): void {
    this.lookupsSubscription = combineLatest([this.professionLookup$, this.specialtyLookup$, this.yesNoOnlyLookup$]).subscribe(
      ([professionLookup, specialtyLookup, yesNoOnlyLookup]) => {
        this.professionLookup = professionLookup;
        this.specialtyLookup = specialtyLookup;
        this.yesNoOnlyLookup = yesNoOnlyLookup;
        this.buildJobCard();
      }
    );
  }

  buildJobCard(): void {
    if (this.submittal.assnStage === AssignmentStageConstants.Application && this.yesNoOnlyLookup.get('No').id === this.submittal.archived) {
      this.isPending = true;
      this.submittalConfig = {
        showStatus: this.submittal.job.closed,
        showInfoDetails: this.config.showInfoDetails,
        showBadges: this.config.showBadges,
        showTransferApplication: this.config.showTransferApplication,
        showLinks: this.config.showLinks
      };
    }

    this.cardModel.title = this.getJobName();
    this.getStatus();
    this.getIcons();
    this.getBadges();
    this.getLinks();

    this.cardModel.jobId = this.submittal.jobOrderId;
    this.cardModel.subtitle = this.submittal.workSite;
    this.cardModel.location = `${this.submittal.city + ', ' + this.submittal.state}`;

    this.cardModel.centerPanel = {
      label: 'Shift',
      value: this._ctx.getShift(this.submittal.shift)
    };
    this.cardModel.rightPanel = {
      label: 'Open Positions',
      value:
        this.submittal.job.quantityRequired === undefined || this.submittal.job.quantityRequired < 0 || this.submittal.job.quantityOpen > this.submittal.job.quantityRequired
          ? `${this.submittal.job.quantityOpen} of ${this.submittal.job.quantityOpen}`
          : `${this.submittal.job.quantityOpen} of ${this.submittal.job.quantityRequired}`
    };

    this.buildPayModel();

    if (this.submittal.jobOrderInactiveDate && this.submittal.matchingInactiveJobOrder) {
      this.cardModel.transferApp = {
        transferName: 'Transfer this application',
        jobOrderInactiveDate: this.submittal.jobOrderInactiveDate,
        matchingInactiveJobOrder: this.submittal.matchingInactiveJobOrder,
        transferFunction: (event: any, id: string) => {
          sessionStorage.setItem('transferredApplicationId', `${this.submittal.id}:${this.submittal.matchingInactiveJobOrder}`);
          this._nav.goToJobsSpecific(id);
          event.stopPropagation();
        }
      };
    }
  }

  getPortalStatusDisplay(submittal: Submittal): number {
    if (submittal.job.closed && submittal.portalStatus !== PortalStatuses.NoLongerAvailable) {
      return PortalStatuses.NoLongerAvailable;
    }

    return submittal.portalStatus;
  }

  buildPayModel(): void {
    if (this.submittal.job.estGrossPay || this.submittal.job.estGrossPay === 0) {
      this.cardModel.leftPanel = {
        label: 'Est Weekly Pay',
        value: this.submittal.job.estGrossPay !== undefined ? `$${this.submittal.job.estGrossPay}` : '????'
      };
    } else {
      this.cardModel.leftPanel = {
        label: 'Est Weekly Pay',
        value: '----'
      };
    }
  }

  getJobName() {
    const specialty = this.specialtyLookup.get(this.submittal.specialty);
    const prof = this.professionLookup.get(this.submittal.profession);
    const profName = prof != null ? prof.shortName : null;
    const specialtyName = specialty?.name != null ? specialty.name : null;

    if (profName && specialtyName) {
      this.jobName = specialtyName + ' Travel ' + profName;
    } else {
      this.jobName = 'Travel ' + profName;
    }
    return this.jobName;
  }

  getIcons(): void {
    const cloneSubmittal = { ...this.submittal };
    if (this.isPending) {
      const editIcon = new CardIconModel();

      editIcon.iconName = 'edit';
      editIcon.clickFunction = (event: any) => {
        event.stopPropagation();
        if (this.completedTasks) {
          this._nav.goToApplicationReview(this.submittal.jobOrderId);
        } else {
          this._nav.goToTasks();
        }
      };
      this.cardModel.icons.push(editIcon);
    } else {
      const index = this.cardModel.icons.findIndex(x => x.iconName === 'edit');
      if (index) {
        this.cardModel.icons.splice(index, 1);
      }
    }
  }

  emitRemoveConfirm(value: any) {
    this.removeConfirm.emit(value);
  }

  emitOutputRemoveAll() {
    this.outputRemoveAll.emit();
  }

  getStatus() {
    this.cardModel.status.statusIcon = 'help';
    this.showSimilarJobsButton = moment(this.submittal.estimatedFeedback3).isBefore(moment(), 'day');
    this.cardModel.status.statusIconFunction = () => {
      this._dialog.open(StatusInfoPopupComponent, {
        panelClass: 'status-info-popup',
        data: this.portalStatus,
        autoFocus: false,
        minHeight: '135px',
        minWidth: '300px',
        width: '90vw',
        maxWidth: '450px',
        restoreFocus: false
      });
    };
    switch (this.portalStatus) {
      case PortalStatuses.OfferAccepted: {
        this.cardModel.status.cssClassName = 'accepted';
        this.cardModel.status.statusText = 'OFFER ACCEPTED';
        this.cardModel.status.statusDescription = 'Contact Recruiter.';
        break;
      }
      case PortalStatuses.OfferDeclined: {
        this.cardModel.status.cssClassName = 'closed';
        this.cardModel.status.statusText = 'OFFER DECLINED';
        this.showArchiveButton = this.yesNoOnlyLookup.get('Yes').id !== this.submittal.archived;
        break;
      }
      case PortalStatuses.Offered: {
        this.cardModel.status.cssClassName = 'offered';
        this.cardModel.status.statusText = 'CONGRATULATIONS! OFFER EXTENDED';
        this.cardModel.status.statusDescription = 'Contact Recruiter.';
        break;
      }
      case PortalStatuses.NoLongerAvailable: {
        this.cardModel.status.cssClassName = 'closed';
        this.showSimilarJobsButton = true;
        this.showArchiveButton = this.yesNoOnlyLookup.get('Yes').id !== this.submittal.archived;
        if (WithdrawnReasonsSubstageArray.includes(this.submittal.assnSubstage)) {
          this.cardModel.status.statusText = `WITHDRAWN: ${moment(this.submittal.submittalWithdrawnDate).format('MM/DD')}`;
        } else {
          this.cardModel.status.statusText = `NO LONGER AVAILABLE: ${moment(this.submittal.jobOrderInactiveDate).format('MM/DD h:mm a')}`;
        }
        break;
      }
      case PortalStatuses.ActionRequired: {
        this.cardModel.status.cssClassName = 'action-required';
        this.cardModel.status.statusText = 'CANDIDATE ACTION NEEDED';
        break;
      }
      case PortalStatuses.PendingReview: {
        this.cardModel.status.cssClassName = 'pending';
        this.cardModel.status.statusText = 'PENDING REVIEW';
        this.canBeWithdrawn = true;
        break;
      }
      case PortalStatuses.HospitalReview: {
        this.cardModel.status.cssClassName = 'hospital-review';
        this.showInterviewButton = true;
        this.cardModel.status.statusText = 'HOSPITAL REVIEW:';
        if (moment(this.submittal.estimatedFeedback).isSameOrAfter(moment(), 'day')) {
          this.cardModel.status.statusDescription = `Estimated feedback by ${moment(this.submittal.estimatedFeedback).format('MM/DD')}.`;
        } else if (moment(this.submittal.estimatedFeedback2).isSameOrAfter(moment(), 'day')) {
          this.cardModel.status.statusDescription = `Estimated feedback by ${moment(this.submittal.estimatedFeedback2).format('MM/DD')}.`;
        } else {
          this.cardModel.status.statusDescription = `Estimated feedback by ${moment(this.submittal.estimatedFeedback3).format('MM/DD')}.`;
        }
        this.canBeWithdrawn = true;
        break;
      }
      default: {
        this.cardModel.status.cssClassName = 'closed';
        this.cardModel.status.statusText = 'STATUS UNKNOWN';
        this.cardModel.status.statusDescription = 'Contact Recruiter.';
      }
    }
  }

  getBadges(): void {
    if (this.submittal.assignmentSource == AssignmentSources.FIL) {
      this.cardModel.badges.push({
        imagePath: '/assets/img/submittal-badges/Badge_FIL.svg',
        sortPriority: 99
      });
    }
  }

  getLinks(): void {
    const cloneSubmittal = { ...this.submittal };
    if (this.showSimilarJobsButton) {
      const similarJobs = new CardLinkModel();
      similarJobs.linkName = 'View Similar Jobs';
      similarJobs.linkFunction = (event: any) => {
        event.stopPropagation();
        this._nav.goToSimilarJobs(cloneSubmittal.jobOrderId);
      };
      this.cardModel.links.push(similarJobs);
    }

    if (this.showInterviewButton && !this.isPending) {
      const interview = new CardLinkModel();
      interview.linkName = `${cloneSubmittal.interviewNotes ? 'Update' : 'Add'} Interview Notes`;
      interview.linkFunction = (event: any) => {
        event.stopPropagation();
        const dlg = this._dialog.open(InterviewPopupComponent, {
          panelClass: 'interview-popup-dialog',
          data: {
            assignmentid: cloneSubmittal.id,
            interviewDate: cloneSubmittal.interviewDate,
            interviewNotes: cloneSubmittal.interviewNotes,
            interviewer: cloneSubmittal.interviewer
          } as Interview,
          autoFocus: false,
          minHeight: '135px',
          minWidth: '300px',
          width: '50vw',
          maxWidth: '450px',
          restoreFocus: false
        });
        dlg.afterClosed().subscribe(o => {
          if (o != null) {
            cloneSubmittal.interviewDate = o.interviewDate;
            cloneSubmittal.interviewNotes = o.interviewNotes;
            cloneSubmittal.interviewer = o.interviewer;
          }
        });
      };

      this.cardModel.links.push(interview);
    }

    if (this.showArchiveButton) {
      const archive = new CardLinkModel();

      archive.linkName = 'Archive';
      archive.linkFunction = (event: any) => {
        event.stopPropagation();
        this.buttonsDisabled = true;
        cloneSubmittal.archived = this.yesNoOnlyLookup.get('Yes').id;
        this.emitArchived(cloneSubmittal.id);
      };
      this.cardModel.links.push(archive);
    }
    if ((this.canBeWithdrawn || this.isPending) && this.portalStatus !== PortalStatuses.NoLongerAvailable) {
      const withdraw = new CardLinkModel();
      withdraw.linkName = 'Withdraw Application';
      withdraw.linkFunction = (event: any) => {
        event.stopPropagation();
        const dlg = this._dialog.open(RemoveSubmittalPopupComponent, {
          data: {
            assignmentId: cloneSubmittal.id,
            stageId: cloneSubmittal.assnStage
          },
          minHeight: '135px',
          minWidth: '300px',
          width: '50vw',
          maxWidth: '450px'
        });

        // Removes selected Submittal
        dlg.componentInstance.outputOnConfirm.subscribe(o => {
          this.emitRemoveConfirm(o);
        });

        // Removes ALL submittals
        dlg.componentInstance.outputRemoveAll.subscribe(() => {
          this.emitOutputRemoveAll();
        });
      };

      this.cardModel.links.push(withdraw);
    }
  }

  emitArchived(id: string) {
    this.archived.emit(id);
  }

  setFiltersFromSubmittal(job: IJob): JobFilterApiBase {
    const model = new JobFilterApiBase();
    let searchLocation = null;
    const state = job.stateName ? job.stateName : this.submittal.state;
    const stateCode = this._ctx.getState(state, true);

    if (job.city === null && stateCode != null) {
      searchLocation = `${stateCode}, USA`;
    } else if (job.city != null && state != null) {
      searchLocation = `${job.city}, ${stateCode}, USA`;
    }

    model.specialties.push(new IFilterGeneral(job.specialty));
    model.profession = new IFilterGeneral(job.profession);
    const lrg: LocationRadiusGroupFilter = {
      location: searchLocation != null ? new IFilterGeneral(searchLocation, searchLocation, true) : null,
      radius: null
    };

    return model;
  }
}
