import { NurseModel } from '../models/nurse-model';
import { IJob } from './job';

export interface IIdToken {
  emails: string[];
  oid: string;
  tfp: string;
}

export interface IUserModel {
  azureObjectId: string;
  hasNurseLink: boolean;
  isInternal: boolean;
  hasContactSelect: boolean;
  isWelcomed: boolean;
  jobNotificationsActive: boolean;
  professionSector?: string;
}

export interface UserContextIN {
  hasNotArrivedInUs: boolean;
  cloudContext: {
    cloudId: string; //Guid
  };
  crmContext: {
    crmId: string; //Guid
  };
  userIdentity: {
    firstName: string;
    lastName: string;
    fullName: string;
  };
  emailAddress: string;
  profilePicture: string;
  isNewUser: boolean;
  immigrationRecords: Immigration[];
  priorityDate: string;
  lifeCycleStageId: string;
  stageLastViewed: string;
  professionId: string;
  profession: string;
  specialty: string;
  showTimeTracking: boolean;
}

export interface Immigration {
  visaTimelineStage: number;
  uscisCaseNumber: string;
  nvcCaseNumber: string;
  nvcInvoiceId: string;
  visaFiledDate: Date;
  priorityDate: Date;
  interviewDate: Date;
}

export interface IRegistrationResponseModel {
  user: IUserModel;
  nurse: NurseModel;
  registrationStatus: number;
}

export interface ISimilarJobsByIdResponseModel {
  originalJobTitle: string;
  results: { totalCount: number; jobs: IJob[] };
}

export interface IRegistrationModel {
  email: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  profession: string;
  specialty?: string;
  subSpecialty?: string;
  specialtyDto?: any;
  travelExperience: number;
  startDate: string;
  teamId: string;
  recruiter: string;
  referralStatus: boolean;
  referralText: string;
  source: string;
  medium: string;
  campaign: string;
  professionSector?: string;
  recruiterId?: string;
}

export interface ICertificationLookupModel {
  id: string;
  certification: string;
  certificationId: string;
  description: string;
  isProfessionalCert?: boolean;
}

export interface IAdUserModel {
  firstName: string;
  lastName: string;
  email: string;
  generateToken: boolean;
}
export interface IAdUserResponseModel {
  userIdentityId: string;
  success: boolean;
  message: string;
  token: string;
  isExistingHCINUser?: boolean;
}
export interface IResetPasswordModel {
  email: string;
}

export interface IImmigrationStageLookupModel {
  id: number;
  name: string;
  miscInfo: string;
}
