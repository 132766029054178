<div fxLayout="row" fxLayout="center center" class="padding">
  <div class="card border full-width" fxLayout="row">
    <div fxFlex="0 0 20%" fxLayout="row" fxLayoutAlign="center center">
      <img class="card-image" src="/assets/img/crisisIcon.svg" />
    </div>
    <div fxFlex fxLayout="column" fxLayoutAlign="space-between start" class="no-background">
      <div class="standard-padding" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
          <div fxLayout="row" class="shrink">Crisis Need Assignments Available Now</div>
          <mat-icon class="smaller-font gray" (click)="crisisDialog()">help_outline</mat-icon>
        </div>
      </div>
      <div class="bottom-padding" fxLayoutAlign="center center">
        <button id="crisisCardView" (click)="viewCrisisJobs()" mat-button color="primary">VIEW ALL CRISIS JOBS NOW</button>
      </div>
    </div>
  </div>
</div>
