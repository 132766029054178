export const dialogContent = {
  items: [
    {
      icon: 'location_on',
      title: 'Desired Locations',
      // eslint-disable-next-line max-len
      text: 'Add states you wish to work in. We will do our best to take your location consideration into preference. You may select multiple states.'
    },
    {
      icon: 'local_hospital',
      title: 'Desired Specialties',
      // eslint-disable-next-line max-len
      text: 'Select the specialty area that you wish to work in within your profession. Employers often look for specific expertise and experience.'
    },
    {
      icon: 'work',
      title: 'Desired Facility',
      // eslint-disable-next-line max-len
      text: 'Certain facilities specialize in providing care for a specific patient demographic or a specific medical need. Select facilities that interest you the most.'
    },
    {
      icon: 'home_work',
      title: 'Desired Community',
      // eslint-disable-next-line max-len
      text: 'Do you want to live in a busy urban city? Or would you prefer a small rural town? Choose the type of community that fits your lifestyle best.'
    },
    {
      icon: 'select_all',
      title: 'Rank Preferences',
      // eslint-disable-next-line max-len
      text: 'We will try our best to match your preferences with job opportunities. We will focus on the preferences you prioritize the highest.'
    },
    {
      icon: 'info',
      title: 'Additional Questions',
      // eslint-disable-next-line max-len
      text: 'If you are flexible, your answers to our additional questions may help speed up the process of finding a job opportunity for you.'
    }
  ]
};

export const placeholders = {
  states: 'Choose at least 5',
  noPreference: 'No Preference',
  noPlaceholder: ''
};

export const errors = {
  states: {
    max: 'Cannot select more than 14 states',
    min: 'Must select at least 5 states'
  },
  cities: {
    max: 'Cannot select more than 3 cities'
  },
  desiredFacilities: {
    max: 'Cannot select more than 2 facility types'
  },
  communityTypes: {
    max: 'Cannot select more than 2 communities'
  },
  desiredSpecialties: {
    max: 'Cannot select more than 10 specialties'
  }
};

export const statesWithCities = ['CA', 'FL', 'NY', 'TX'];

export const preferenceTypes = {
  location: 'Location',
  facilityType: 'Facility Type',
  community: 'Community',
  specialty: 'Specialty'
};

export const preferenceTypeDisplayNames = {
  states: 'States',
  facilityTypes: 'Facility Types',
  community: 'Community',
  specialty: 'Specialty'
};

export const jobPreferenceINToastMessages = {
  success: 'Your profile changes have been successfully saved.',
  error: 'We were unable to save your changes. Please try again.'
};
