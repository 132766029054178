export interface AccommodationDetails {
  id: string;
  accommodationName: string;
  phoneNumber: string;
  confirmationNo: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  reservationDates: string;
  checkInDate: string;
  checkOutDate: string;
  duration: string;
  cost: string;
}
