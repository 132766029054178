import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, retry, switchMap } from 'rxjs/operators';
import { HCINPortalAPIService } from 'src/app/services';
import { ArrivalsActions, EArrivalsActions, GetArrivalsError, GetArrivalsSuccess } from './arrivals.actions';
import { Store } from '@ngrx/store';
import { Arrivals } from '../../common/models/arrivals';
import { selectCanSeeInternational } from '../userContext/userContext.selectors';

@Injectable({
  providedIn: 'root'
})
export class ArrivalsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly _hcinPortalApiService: HCINPortalAPIService,
    private readonly _store: Store
  ) {}

  getArrivals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ArrivalsActions>(EArrivalsActions.GetArrivals),
      concatLatestFrom(() => this._store.select(selectCanSeeInternational)),
      filter(([, selectCanSeeInternational]) => !!selectCanSeeInternational),
      switchMap(() => {
        return this._hcinPortalApiService.getArrivals().pipe(
          retry(1),
          map((data: Arrivals) => new GetArrivalsSuccess(data)),
          catchError((error: Error) => of(new GetArrivalsError(error)))
        );
      })
    );
  });
}
