export class HasId {
  public id: string;
}

export class AboutMeModel extends HasId {
  email: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  dateOfBirth: Date;
  ssn: string;
  ssnViewValue: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  zipCode: string;

  // hcin specific fields
  preferredName: string;
  gender: number;
  pronoun: string;
  otherPronouns: string;
  professionId: string;
  merlinId: string;
}

export class ProfessionalOverviewModel extends HasId {
  public workExperience?: number;
  public travelExperience?: number;
  public specialtyDto?: any;
  public specialtyId?: string;
  public profession?: string;
  public specialtyExperience?: number;
  public sizzle: string;
  public userSpecialty?: string;
  public userSubSpecialty?: string;
  public userProfession?: string;
}

export class NurseProfileModel extends AboutMeModel {
  public mobilePhone: string;
  public specialty: any;
  public specialtyId: any;
  public secondarySpecialty: any;
  public secondarySpecialtyId: string;
  public profession: string;
  public workExperience: number;
  public travelExperience: number;
  public specialtyExperience: number;
  public secondarySpecialtyExperience: number;
  public recruiter: string;
  public profilePicture: any;
  public sizzle: string;
  public dateAvailable: Date;
  public requestedTimeOff: string;
}
