import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  HcinEducation,
  UserContextIN,
  HcinContactModel,
  IFileUploadOptions,
  FileCategoriesNames,
  INAssignment,
  HcinWorkHistory,
  LicenseIN,
  HcinCertification,
  API_ROUTES,
  Arrivals
} from '../common';
import { CurrentAssigneeInformation } from '../common/models/recruiter';
import { ApiOptions } from './nurse-portal-api.service';
import { v4 as uuidv4 } from 'uuid';
import { DocumentUploaded } from '../store/segment/segment.actions';
import { IAppState } from '../store/app/app.state';
import { Store } from '@ngrx/store';
import { JobPreferencesIN } from '../common/models/job-preference';

@Injectable({
  providedIn: 'root'
})
export class HCINPortalAPIService {
  private readonly httpOptions: {
    headers?: HttpHeaders;
  };

  constructor(
    private readonly _http: HttpClient,
    private readonly _store: Store<IAppState>
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': environment.internationalApimSubscriptionKey
      })
    };
  }

  private _determineFileName(options: IFileUploadOptions, file: File, counter: number): string {
    if (file.name.length > 50) {
      const fileExt = file.name.split('.').pop();
      if (options.itemId) {
        return `${options.itemId}_${counter}.${fileExt}`;
      } else {
        return `${uuidv4()}.${fileExt}`;
      }
    }
    return file.name;
  }

  private _getHeaders(options?: ApiOptions): HttpHeaders {
    let headers = this.httpOptions.headers ?? new HttpHeaders();

    headers = headers.delete('Content-Type');

    if (options) {
      if (options.showBusyIndicator === false) {
        headers = headers.append('ignoreProgressBar', '');
      }

      if (options.useServiceWorker === false) {
        headers = headers.append('ngsw-bypass', 'true');
      }
    }

    return headers;
  }

  getLookups(): Observable<any> {
    return this._http.get<any>(`${environment.internationalApiBaseUrl}/Lookups`, this.httpOptions);
  }

  getUser(): Observable<UserContextIN> {
    return this._http.get<UserContextIN>(`${environment.internationalApiBaseUrl}/UserIdentity/me`, this.httpOptions);
  }

  getCurrentAssigneeInformation(): Observable<CurrentAssigneeInformation> {
    return this._http.get<CurrentAssigneeInformation>(`${environment.internationalApiBaseUrl}/CurrentAssignee`, this.httpOptions);
  }

  getNurseState(): Observable<any> {
    return this._http.get<HcinContactModel>(`${environment.internationalApiBaseUrl}/Contacts`, this.httpOptions);
  }

  getCertifications(): Observable<HcinCertification[]> {
    return this._http.get<HcinCertification[]>(`${environment.internationalApiBaseUrl}/${API_ROUTES.certifications}`, this.httpOptions);
  }
  addCertification(certification: HcinCertification): Observable<string> {
    const endpoint = certification.isProfessionalCert ? 'add-professional-certification' : 'add-certification';
    return this._http.post<string>(`${environment.internationalApiBaseUrl}/Certifications/${endpoint}`, certification, this.httpOptions);
  }
  addLanguageProficiency(certification: HcinCertification): Observable<string> {
    return this._http.post<string>(`${environment.internationalApiBaseUrl}/Certifications/add-language-proficiency`, certification, this.httpOptions);
  }
  getWorkHistories(): Observable<HcinWorkHistory[]> {
    return this._http.get<HcinWorkHistory[]>(`${environment.internationalApiBaseUrl}/WorkHistory`, this.httpOptions);
  }

  addWorkHistory(workHistory: HcinWorkHistory): Observable<string> {
    return this._http.post<string>(`${environment.internationalApiBaseUrl}/WorkHistory`, workHistory, this.httpOptions);
  }

  updateAboutMe(model: HcinContactModel): Observable<any> {
    return this._http.put<HcinContactModel>(`${environment.internationalApiBaseUrl}/Contacts`, model, this.httpOptions);
  }

  getEducations(): Observable<HcinEducation[]> {
    return this._http.get<HcinEducation[]>(`${environment.internationalApiBaseUrl}/Education`, this.httpOptions);
  }

  // Add Education
  addEducation(education: HcinEducation): Observable<string> {
    return this._http.post<string>(`${environment.internationalApiBaseUrl}/Education`, education, this.httpOptions);
  }

  public fileUpload(options: IFileUploadOptions, files: Array<File>): Observable<any> {
    const apiOptions = new ApiOptions().byPassServiceWorker();
    const formData = new FormData();
    const myHeaders = this._getHeaders(apiOptions);
    formData.append('options', JSON.stringify(options));
    files.forEach((o, counter) => {
      const newFileName = this._determineFileName(options, o, counter);
      formData.append('uploadedFile', o, newFileName);
    });
    return this._http.post<any>(`${environment.internationalApiBaseUrl}/Files`, formData, { headers: myHeaders, observe: 'response' }).pipe(
      tap(response => {
        if (response.status == 200 || response.returnValue || response.result) {
          const docInfos = response.returnValue || response.result;
          if (Array.isArray(docInfos)) {
            docInfos.forEach(docInfo => {
              if (docInfo) {
                const category = FileCategoriesNames.get(docInfo.category);
                const payload = {
                  documentId: docInfo.id,
                  documentName: docInfo.fileName,
                  documentType: category,
                  documentURL: docInfo.fileDownloadUrl
                };
                this._store.dispatch(new DocumentUploaded(payload));
              }
            });
          } else if (docInfos?.id) {
            const category = FileCategoriesNames.get(docInfos.category);
            const payload = {
              documentId: docInfos.id,
              documentName: docInfos.fileName,
              documentType: category,
              documentURL: docInfos.fileDownloadUrl
            };
            this._store.dispatch(new DocumentUploaded(payload));
          }
        }
      })
    );
  }

  getAssignments(): Observable<INAssignment[]> {
    return this._http.get<INAssignment[]>(`${environment.internationalApiBaseUrl}/Assignments`, this.httpOptions);
  }

  getLicenses(): Observable<LicenseIN[]> {
    return this._http.get<LicenseIN[]>(`${environment.internationalApiBaseUrl}/Licenses`, this.httpOptions);
  }

  addLicense(model: LicenseIN): Observable<any> {
    return this._http.post<LicenseIN>(`${environment.internationalApiBaseUrl}/Licenses`, model, this.httpOptions);
  }

  GetAssignmentById(id: string): Observable<INAssignment> {
    return this._http.get<INAssignment>(`${environment.internationalApiBaseUrl}/Assignments/get-single-assignment?id=${id}`, this.httpOptions);
  }

  getJobPreferences(): Observable<JobPreferencesIN> {
    return this._http.get<JobPreferencesIN>(`${environment.internationalApiBaseUrl}/Preferences`, this.httpOptions);
  }

  getArrivals(): Observable<Arrivals> {
    return this._http.get<Arrivals>(`${environment.internationalApiBaseUrl}/Arrivals`, this.httpOptions);
  }
  upsertJobPreferences(model: JobPreferencesIN): Observable<string> {
    return this._http.put<string>(`${environment.internationalApiBaseUrl}/Preferences`, model, this.httpOptions);
  }
}
