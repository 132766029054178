export class JobOrderModel {
  id: string;
  applied: boolean;
  assignmentLength: number;
  certificationRequirements: any[];
  city: string;
  complianceRequirements: any[];
  createdOn: Date;
  endDate: Date;
  facilityId: string;
  hospital: string;
  hospitalId: string;
  hospitalInfo: any;
  hoursPerWeek: number;
  interested: boolean;
  latitude: number;
  longitude: number;
  modifiedOn: Date;
  name: string;
  profession: string;
  quantityOpen: number;
  rating: number;
  specialty: string;
  startDate: Date;
  state: string;
  stateCode: number;
  statusCode: number;
}
