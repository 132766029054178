import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: []
})
export class AutoCompleteComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() placeholder: string;
  @Input() controlName: string;
  @Input() filteredList: Observable<any[]>;
  @Input() clearButton = true;
  @Input() matErrorText = '';
  @Input() required = true;
  @Input() displayFunction: (item: any) => string;
  @Output() optionSelected = new EventEmitter<MatAutocompleteSelectedEvent>();

  ngOnInit() {
    // DONT SHOW CLEAR BUTTON IF CONTROL IS DISABLED
    if (this.form.controls[this.controlName].disabled === true) {
      this.clearButton = false;
    }
  }

  autoOptionSelected(item: MatAutocompleteSelectedEvent) {
    this.optionSelected.emit(item);
  }

  clear() {
    this.form.controls[this.controlName].setValue('');
    this.form.markAsDirty();
  }

  getName(item: any) {
    if (item.name != null) {
      return item.name;
    } else if (item.shortName != null) {
      return item.shortName;
    } else if (item.code != null) {
      return item.code;
    } else if (item.certification != null) {
      return item.certification;
    } else if (item.systemTechnology != null) {
      return item.systemTechnology;
    } else if (item.zipCode != null && item.city != null) {
      // ZipCodeModel
      return `${item.zipCode} - ${item.city} - ${item.country}`;
    }
  }
}
