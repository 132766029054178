<div id="edit-certification-bottom-sheet" fxLayout="column">
  <div class="header-area" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <button id="addFileBottomSheetClose" mat-icon-button color="primary" (click)="cancel()">
        <mat-icon class="global-icon-gray">close</mat-icon>
      </button>
      <div class="header global-title">Replace File</div>
    </div>
    <button id="addFileBottomSheetDone" mat-flat-button color="primary" (click)="done()" [disabled]="files.length === 0">Ok</button>
  </div>
  <mat-divider></mat-divider>
  <div class="overflow filter-form-area">
    <div fxLayout="column" fxLayoutGap="16px">
      <div fxLayout="column" fxLayoutGap="8px">
        <div class="global-subheader">DOCUMENT</div>
        <div
          *ngIf="files.length === 0"
          class="upload-area a"
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="4x"
          (click)="fileInput.click()"
          matRipple
          [matRippleCentered]="true"
        >
          <mat-icon>camera_alt</mat-icon>
          <button id="addFileBottomSheetUploadDocument" mat-button color="primary">SNAP OR UPLOAD</button>
        </div>

        <div *ngIf="files.length > 0">
          <div *ngFor="let file of files" fxLayoutAlign="space-between center">
            <div>{{ file.name }}</div>
            <mat-icon (click)="clearFiles()">clear</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<input #fileInput type="file" accept="image/*;capture=camera" (change)="handleFileInput($event.target.files, fileInput)" style="display: none" />
