import { Action } from '@ngrx/store';
import { Arrivals } from '../../common/models/arrivals';

export enum EArrivalsActions {
  GetArrivals = '[ARRIVALS] get arrivals',
  GetArrivalsSuccess = '[ARRIVALS] get arrivals success',
  GetArrivalsError = '[ARRIVALS] get arrivals error'
}

export class GetArrivals implements Action {
  public readonly type = EArrivalsActions.GetArrivals;
}

export class GetArrivalsSuccess implements Action {
  public readonly type = EArrivalsActions.GetArrivalsSuccess;

  constructor(public payload: Arrivals) {}
}
export class GetArrivalsError implements Action {
  public readonly type = EArrivalsActions.GetArrivalsError;

  constructor(public error: Error) {}
}

export type ArrivalsActions = GetArrivals | GetArrivalsSuccess | GetArrivalsError;
