<div fxFlex fxLayout="column" id="main-content" fxLayoutGap="16px">
  <div fxLayout="column" class="top-section">
    <div fxLayoutAlign="end end">
      <button id="interestedPopupCancel" mat-raised-button color="primary" (click)="cancel()">OK</button>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
      <mat-icon class="check material-icons md-48">check_circle</mat-icon>
      <div class="top-header">Thank you for your interest. Your recruiter will reach out soon.</div>
    </div>
  </div>
</div>
