import { Action } from '@ngrx/store';
import {
  ApplicationReviewModel,
  IJob,
  IJobFilterAPI,
  INAssignment,
  ISimilarJobsByIdResponseModel,
  SimilarJobByIdSearchModel,
  SimilarJobSearchModel,
  Submittal,
  TaskCompletionReturnObject
} from 'src/app/common';
import { IJobPreferences, JobPreferenceReturn, JobPreferencesIN } from 'src/app/common/models/job-preference';
import { JobDataModel } from 'src/app/common/models/job-data-model';
import { ContractType } from 'src/app/common/contracts/contract-type';

export enum EJobsActions {
  GetJobPreferences = '[JOBS] get jobPreferences',
  GetJobPreferencesSuccess = '[JOBS] get jobPreferences success',
  GetJobPreferencesError = '[JOBS] get jobPreferences error',
  GetJobPreferencesINSuccess = '[JOBS] get jobPreferences IN success',
  GetJobPreferencesINError = '[JOBS] get jobPreferences IN error',
  GetAvailableJobs = '[JOBS] get available jobs',
  GetAvailableJobsSuccess = '[JOBS] get available jobs success',
  GetAvailableJobsError = '[JOBS] get available jobs error',
  GetSimilarJobs = '[JOBS] get similar jobs',
  GetSimilarJobsSuccess = '[JOBS] get similar jobs success',
  GetSimilarJobsSuccessMapped = '[JOBS] get similar jobs success and added contractType property',
  GetSimilarJobsError = '[JOBS] get similar jobs error',
  GetSimilarJobsByJobId = '[JOBS] get similar jobs by job id',
  GetSimilarJobsByJobIdSuccess = '[JOBS] get similar jobs success by job id',
  GetSimilarJobsByJobIdError = '[JOBS] get similar jobs error by job id',
  SetJobPreferences = '[JOBS] set job preferences',
  SetJobPreferencesSuccess = '[JOBS] set job preferences success',
  SetJobPreferencesError = '[JOBS] set job preferences error',
  ResetJobPreferenceSubmitResult = '[JOBS] reset job preferences submit result',
  GetRecommendedJobsByPreference = '[JOBS] get recommended jobs by preference',
  GetRecommendedJobsByPreferenceSuccess = '[JOBS] get recommended jobs by preference success',
  GetRecommendedJobsByPreferenceError = '[JOBS] get recommended jobs by preference error',
  GetJobSubmittals = '[JOBS] get jobSubmittals',
  GetJobSubmittalsSuccess = '[JOBS] jobSubmittals success',
  GetJobSubmittalsError = '[JOBS] jobSubmittals error',
  GetJobSubmittalsIN = '[JOBS] get job submittals IN',
  GetJobSubmittalsINSuccess = '[JOBS] get job submittals IN success',
  GetJobSubmittalsINError = '[JOBS] get job submittals IN error',
  ArchiveSubmittal = '[JOBS] archiveSubmittal',
  ArchiveSubmittalSuccess = '[JOBS] archiveSubmittal success',
  ArchiveSubmittalError = '[JOBS] archiveSubmittal error',
  GetSavedJobs = '[JOBS] get saved jobs',
  GetSavedJobsSuccess = '[JOBS] get saved jobs success',
  GetSavedJobsError = '[JOBS] get saved jobs error',
  SetSavedJob = '[JOBS] set saved job',
  SetSavedJobSuccess = '[JOBS] set saved job success',
  SetSavedJobError = '[JOBS] set saved job error',
  SetSavedRecommendedJob = '[JOBS] set saved recommended job',
  SetSavedRecommendedJobSuccess = '[JOBS] set saved recommended job success',
  SetSavedAvailableJob = '[JOBS] set saved available job',
  SetSavedAvailableJobSuccess = '[JOBS] set saved available job success',
  SetSavedSimilarJob = '[JOBS] set saved similar job',
  SetSavedSimilarJobSuccess = '[JOBS] set saved similar job success',
  GetJobFilterV2 = '[JOBS] get job filter V2',
  SetJobFilterV2 = '[JOBS] set job filter V2',
  SetContractType = '[JOBS] get contractType',
  GetLocationsByName = '[JOBS] get locations by name',
  GetLocationsByNameSuccess = '[JOBS] get locations by name success',
  GetLocationsByNameError = '[JOBS] get locations by name error',
  GetSpecificJob = '[JOBS] get specific job',
  GetSpecificJobSuccess = '[JOBS] get specific job success',
  GetSpecificJobError = '[JOBS] get specific job error',
  GetSpecificJobNotFound = '[JOBS] get specific job not found',
  GetSpecificAssignment = '[JOBS] get specific assignment',
  GetSpecificAssignmentSuccess = '[JOBS] get specific assignment success',
  GetSpecificAssignmentError = '[JOBS] get specific assignment error',
  ClearSpecificJob = '[JOBS] clear specific job data',
  GetSimilarJobsByFacilityId = '[JOBS] get similar jobs by facility',
  GetSimilarJobsByFacilityIdSuccess = '[JOBS] get similar jobs by facility success',
  GetSimilarJobsByFacilityIdError = '[JOBS] get similar jobs by facility error',
  GetMapUrlForFacility = '[JOBS] get map url for facility',
  GetMapUrlForFacilitySuccess = '[JOBS] get map url for facility success',
  GetMapUrlForFacilityError = '[JOBS] get map url for facility error',
  GetHasAutoOfferAssignments = '[JOBS] get hasAutoOfferAssignments',
  GetHasAutoOfferAssignmentsSuccess = '[JOBS] get hasAutoOfferAssignments success',
  GetHasAutoOfferAssignmentsError = '[JOBS] get hasAutoOfferAssignments error',
  UpdateJobNotifications = '[JOBS] update job notifications',
  UpdateJobNotificationsSuccess = '[JOBS] update job notifications success',
  UpdateJobNotificationsError = '[JOBS] update job notifications error',
  CupidEventRecord = '[JOBS] cupid event record',
  GetRecommendedMatchedJobs = '[JOBS] get recommended matched jobs',
  GetRecommendedMatchedJobsSuccess = '[JOBS] get recommended matched jobs success',
  GetRecommendedMatchedJobsError = '[JOBS] get recommended matched jobs error',
  ApplyToSpecificJob = '[JOBS] apply to specific job',
  ApplyToSpecificJobSuccess = '[JOBS] apply to specific job success',
  ApplyToSpecificJobError = '[JOBS] apply to specific job error',
  UpdateApplication = '[JOBS] update application',
  UpdateApplicationSuccess = '[JOBS] update application success',
  UpdateApplicationError = '[JOBS] update application error',
  ResetApplyToSpecificJob = '[JOBS] reset apply to specific jobs result',
  ResetUpdateApplication = '[JOBS] reset update application result',
  GetApplication = '[JOBS] get application review',
  GetApplicationSuccess = '[JOBS] get application review success',
  GetApplicationError = '[JOBS] get application review error',
  UpdateProfileReviewData = '[JOBS] upload profile review data',
  UpdateProfileReviewDataSuccess = '[JOBS] upload profile review data success',
  UpdateProfileReviewDataError = '[JOBS] upload profile review data error',
  ResetUpdateProfileReviewResult = '[JOBS] reset upload profile review result',
  BackToJobSearch = '[JOBS] back to job search',
  UpsertJobPreferencesIN = '[JOBS] upsert job preferences IN',
  UpsertJobPreferencesINSuccess = '[JOBS] upsert job preferences IN success',
  UpsertJobPreferencesINError = '[JOBS] upesrt job preferences IN error'
}

export class GetJobPreferences implements Action {
  public readonly type = EJobsActions.GetJobPreferences;
}

export class GetJobPreferencesSuccess implements Action {
  public readonly type = EJobsActions.GetJobPreferencesSuccess;

  constructor(public payload: JobPreferenceReturn) {}
}

export class GetJobPreferencesError implements Action {
  public readonly type = EJobsActions.GetJobPreferencesError;

  constructor(public error: Error) {}
}

export class GetJobPreferencesINSuccess implements Action {
  public readonly type = EJobsActions.GetJobPreferencesINSuccess;

  constructor(public payload: JobPreferencesIN) {}
}

export class GetJobPreferencesINError implements Action {
  public readonly type = EJobsActions.GetJobPreferencesINError;

  constructor(public error: Error) {}
}
export class GetAvailableJobs implements Action {
  public readonly type = EJobsActions.GetAvailableJobs;

  constructor(public model: IJobFilterAPI) {}
}

export class GetAvailableJobsSuccess implements Action {
  public readonly type = EJobsActions.GetAvailableJobsSuccess;

  constructor(
    public payload: JobDataModel,
    public reload: boolean,
    public totalCount: number
  ) {}
}

export class GetAvailableJobsError implements Action {
  public readonly type = EJobsActions.GetAvailableJobsError;

  constructor(public error: Error) {}
}

export class GetSimilarJobs implements Action {
  public readonly type = EJobsActions.GetSimilarJobs;

  constructor(public input: SimilarJobSearchModel) {}
}

export class GetSimilarJobsSuccess implements Action {
  public readonly type = EJobsActions.GetSimilarJobsSuccess;

  constructor(public payload: JobDataModel) {}
}

export class GetSimilarJobsSuccessMapped implements Action {
  public readonly type = EJobsActions.GetSimilarJobsSuccessMapped;

  constructor(public payload: JobDataModel) {}
}

export class GetSimilarJobsError implements Action {
  public readonly type = EJobsActions.GetSimilarJobsError;

  constructor(public error: Error) {}
}

export class GetSimilarJobsByJobId implements Action {
  public readonly type = EJobsActions.GetSimilarJobsByJobId;

  constructor(public input: SimilarJobByIdSearchModel) {}
}

export class GetSimilarJobsByJobIdSuccess implements Action {
  public readonly type = EJobsActions.GetSimilarJobsByJobIdSuccess;

  constructor(public payload: ISimilarJobsByIdResponseModel) {}
}

export class GetSimilarJobsByJobIdError implements Action {
  public readonly type = EJobsActions.GetSimilarJobsByJobIdError;

  constructor(public error: Error) {}
}

export class ResetJobPreferenceSubmitResult implements Action {
  public readonly type = EJobsActions.ResetJobPreferenceSubmitResult;
}

export class SetJobPreferences implements Action {
  public readonly type = EJobsActions.SetJobPreferences;

  constructor(public payload: IJobPreferences) {}
}

export class SetJobPreferencesSuccess implements Action {
  public readonly type = EJobsActions.SetJobPreferencesSuccess;

  constructor(public response: JobPreferenceReturn) {}
}

export class SetJobPreferencesError implements Action {
  public readonly type = EJobsActions.SetJobPreferencesError;

  constructor(public error: Error) {}
}

export class GetRecommendedJobsByPreference implements Action {
  public readonly type = EJobsActions.GetRecommendedJobsByPreference;
}

export class GetRecommendedJobsByPreferenceSuccess implements Action {
  public readonly type = EJobsActions.GetRecommendedJobsByPreferenceSuccess;

  constructor(public payload: IJob[]) {}
}

export class GetRecommendedJobsByPreferenceError implements Action {
  public readonly type = EJobsActions.GetRecommendedJobsByPreferenceError;

  constructor(public error: Error) {}
}

export class GetJobSubmittals implements Action {
  public readonly type = EJobsActions.GetJobSubmittals;

  constructor(public forceUpdateCache = false) {}
}

export class GetJobSubmittalsSuccess implements Action {
  public readonly type = EJobsActions.GetJobSubmittalsSuccess;

  constructor(public payload: { submittals: Submittal[]; expiration: Date }) {}
}

export class GetJobSubmittalsError implements Action {
  public readonly type = EJobsActions.GetJobSubmittalsError;

  constructor(public error: Error) {}
}

export class GetJobSubmittalsIN implements Action {
  public readonly type = EJobsActions.GetJobSubmittalsIN;
}

export class GetJobSubmittalsINSuccess implements Action {
  public readonly type = EJobsActions.GetJobSubmittalsINSuccess;

  constructor(public payload: { submittals: INAssignment[] }) {}
}

export class GetJobSubmittalsINError implements Action {
  public readonly type = EJobsActions.GetJobSubmittalsINError;

  constructor(public error: Error) {}
}

export class ArchiveSubmittal implements Action {
  public readonly type = EJobsActions.ArchiveSubmittal;

  constructor(public payload: { id: string }) {}
}

export class ArchiveSubmittalSuccess implements Action {
  public readonly type = EJobsActions.ArchiveSubmittalSuccess;

  constructor(public payload: { id: string }) {}
}

export class ArchiveSubmittalError implements Action {
  public readonly type = EJobsActions.ArchiveSubmittalError;

  constructor(public error: Error) {}
}

export class GetSavedJobs implements Action {
  public readonly type = EJobsActions.GetSavedJobs;
}

export class GetSavedJobsSuccess implements Action {
  public readonly type = EJobsActions.GetSavedJobsSuccess;

  constructor(public payload: JobDataModel) {}
}

export class GetSavedJobsError implements Action {
  public readonly type = EJobsActions.GetSavedJobsError;

  constructor(public error: Error) {}
}

export class SetSavedJob implements Action {
  public readonly type = EJobsActions.SetSavedJob;

  constructor(
    public payload: {
      job: IJob;
      saveValue: boolean;
      contractType?: ContractType;
    }
  ) {}
}

export class SetSavedJobSuccess implements Action {
  public readonly type = EJobsActions.SetSavedJobSuccess;

  constructor(public payload: { value: number; job: IJob; saveValue: boolean; contractType?: ContractType }) {}
}

export class SetSavedJobError implements Action {
  public readonly type = EJobsActions.SetSavedJobError;

  constructor(
    public errorPayload: {
      error: Error;
      saveData: { job: IJob; saveValue: boolean; contractType?: ContractType };
    }
  ) {}
}

export class SetSavedRecommendedJob implements Action {
  public readonly type = EJobsActions.SetSavedRecommendedJob;

  constructor(
    public payload: {
      job: IJob;
      saveValue: boolean;
      contractType?: ContractType;
    }
  ) {}
}

export class SetSavedRecommendedJobSuccess implements Action {
  public readonly type = EJobsActions.SetSavedRecommendedJobSuccess;

  constructor(
    public payload: {
      job: IJob;
      saveValue: boolean;
      contractType?: ContractType;
    }
  ) {}
}

export class SetSavedAvailableJob implements Action {
  public readonly type = EJobsActions.SetSavedAvailableJob;

  constructor(
    public payload: {
      job: IJob;
      saveValue: boolean;
      contractType?: ContractType;
    }
  ) {}
}

export class SetSavedAvailableJobSuccess implements Action {
  public readonly type = EJobsActions.SetSavedAvailableJobSuccess;

  constructor(public payload: { job: IJob; saveValue: boolean }) {}
}

export class SetSavedSimilarJob implements Action {
  public readonly type = EJobsActions.SetSavedSimilarJob;

  constructor(
    public payload: {
      job: IJob;
      saveValue: boolean;
      contractType?: ContractType;
    }
  ) {}
}

export class SetSavedSimilarJobSuccess implements Action {
  public readonly type = EJobsActions.SetSavedSimilarJobSuccess;

  constructor(
    public payload: {
      job: IJob;
      saveValue: boolean;
      contractType?: ContractType;
    }
  ) {}
}

export class GetJobFilterV2 implements Action {
  public readonly type = EJobsActions.GetJobFilterV2;
}

export class SetJobFilterV2 implements Action {
  public readonly type = EJobsActions.SetJobFilterV2;

  constructor(public filter: IJobFilterAPI) {}
}

export class SetContractType implements Action {
  public readonly type = EJobsActions.SetContractType;

  constructor(public contractType: ContractType) {}
}

export class GetLocationsByName implements Action {
  public readonly type = EJobsActions.GetLocationsByName;

  constructor(public payload: string) {}
}

export class GetLocationsByNameSuccess implements Action {
  public readonly type = EJobsActions.GetLocationsByNameSuccess;

  constructor(public response: any[]) {}
}

export class GetLocationsByNameError implements Action {
  public readonly type = EJobsActions.GetLocationsByNameError;

  constructor(public error: Error) {}
}

export class GetSpecificJob implements Action {
  public readonly type = EJobsActions.GetSpecificJob;

  constructor(public jobId: string) {}
}

export class GetSpecificJobSuccess implements Action {
  public readonly type = EJobsActions.GetSpecificJobSuccess;

  constructor(public payload: IJob) {}
}

export class GetSpecificJobError implements Action {
  public readonly type = EJobsActions.GetSpecificJobError;

  constructor(public error: Error) {}
}

export class GetSpecificJobNotFound implements Action {
  public readonly type = EJobsActions.GetSpecificJobNotFound;

  constructor(public found: boolean) {}
}

export class GetSpecificAssignment implements Action {
  public readonly type = EJobsActions.GetSpecificAssignment;

  constructor(public assignmentId: string) {}
}

export class GetSpecificAssignmentSuccess implements Action {
  public readonly type = EJobsActions.GetSpecificAssignmentSuccess;

  constructor(public payload: INAssignment) {}
}

export class GetSpecificAssignmentError implements Action {
  public readonly type = EJobsActions.GetSpecificAssignmentError;

  constructor(public error: Error) {}
}

export class ClearSpecificJob implements Action {
  public readonly type = EJobsActions.ClearSpecificJob;
}

export class GetSimilarJobsByFacilityId implements Action {
  public readonly type = EJobsActions.GetSimilarJobsByFacilityId;

  constructor(public facilityId: string) {}
}

export class GetSimilarJobsByFacilityIdSuccess implements Action {
  public readonly type = EJobsActions.GetSimilarJobsByFacilityIdSuccess;

  constructor(public payload: IJob[]) {}
}

export class GetSimilarJobsByFacilityIdError implements Action {
  public readonly type = EJobsActions.GetSimilarJobsByFacilityIdError;

  constructor(public error: Error) {}
}

export class GetMapUrlForFacility implements Action {
  public readonly type = EJobsActions.GetMapUrlForFacility;

  constructor(public addr: string) {}
}

export class GetMapUrlForFacilitySuccess implements Action {
  public readonly type = EJobsActions.GetMapUrlForFacilitySuccess;

  constructor(public url: string) {}
}

export class GetMapUrlForFacilityError implements Action {
  public readonly type = EJobsActions.GetMapUrlForFacilityError;

  constructor(public error: Error) {}
}

export class UpdateJobNotifications implements Action {
  public readonly type = EJobsActions.UpdateJobNotifications;

  constructor(public payload: { merlinId: string; value: boolean }) {}
}

export class UpdateJobNotificationsSuccess implements Action {
  public readonly type = EJobsActions.UpdateJobNotificationsSuccess;
}

export class UpdateJobNotificationsError implements Action {
  public readonly type = EJobsActions.UpdateJobNotificationsError;

  constructor(public error: Error) {}
}

export class GetHasAutoOfferAssignments implements Action {
  public readonly type = EJobsActions.GetHasAutoOfferAssignments;
}

export class GetHasAutoOfferAssignmentsSuccess implements Action {
  public readonly type = EJobsActions.GetHasAutoOfferAssignmentsSuccess;

  constructor(public response: boolean) {}
}

export class GetHasAutoOfferAssignmentsError implements Action {
  public readonly type = EJobsActions.GetHasAutoOfferAssignmentsError;

  constructor(public error: Error) {}
}

export class CupidEventRecord implements Action {
  public readonly type = EJobsActions.CupidEventRecord;

  constructor(public jobId: string) {}
}

export class GetRecommendedMatchedJobs implements Action {
  public readonly type = EJobsActions.GetRecommendedMatchedJobs;
}

export class GetRecommendedMatchedJobsSuccess implements Action {
  public readonly type = EJobsActions.GetRecommendedMatchedJobsSuccess;

  constructor(public payload: IJob[]) {}
}

export class GetRecommendedMatchedJobsError implements Action {
  public readonly type = EJobsActions.GetRecommendedMatchedJobsError;

  constructor(public error: Error) {}
}

export class ApplyToSpecificJob implements Action {
  public readonly type = EJobsActions.ApplyToSpecificJob;

  constructor(
    public specificJob: IJob,
    public rto: string = '',
    public hasCompletedTasks: boolean = false,
    public isRecommended: boolean = false,
    public contractType: ContractType = null
  ) {}
}

export class ApplyToSpecificJobSuccess implements Action {
  public readonly type = EJobsActions.ApplyToSpecificJobSuccess;

  constructor(public response: number) {}
}

export class ApplyToSpecificJobError implements Action {
  public readonly type = EJobsActions.ApplyToSpecificJobError;

  constructor(public error: Error) {}
}

export class UpdateApplication implements Action {
  public readonly type = EJobsActions.UpdateApplication;

  constructor(
    public specificJob: IJob,
    public rto: string = '',
    public hasCompletedTasks: boolean = false,
    public isRecommended: boolean = false
  ) {}
}

export class UpdateApplicationSuccess implements Action {
  public readonly type = EJobsActions.UpdateApplicationSuccess;

  constructor(public response: number) {}
}

export class UpdateApplicationError implements Action {
  public readonly type = EJobsActions.UpdateApplicationError;

  constructor(public error: Error) {}
}

export class ResetUpdateApplication implements Action {
  public readonly type = EJobsActions.ResetUpdateApplication;
}

export class ResetApplyToSpecificJob implements Action {
  public readonly type = EJobsActions.ResetApplyToSpecificJob;
}

export class GetApplication implements Action {
  public readonly type = EJobsActions.GetApplication;
}

export class GetApplicationSuccess implements Action {
  public readonly type = EJobsActions.GetApplicationSuccess;

  constructor(public response: any) {}
}

export class GetApplicationError implements Action {
  public readonly type = EJobsActions.GetApplicationError;

  constructor(public error: Error) {}
}

export class UpdateProfileReviewData implements Action {
  public readonly type = EJobsActions.UpdateProfileReviewData;

  constructor(public model: ApplicationReviewModel) {}
}

export class UpdateProfileReviewDataSuccess implements Action {
  public readonly type = EJobsActions.UpdateProfileReviewDataSuccess;

  constructor(public response: TaskCompletionReturnObject) {}
}

export class UpdateProfileReviewDataError implements Action {
  public readonly type = EJobsActions.UpdateProfileReviewDataError;

  constructor(public error: Error) {}
}

export class ResetUpdateProfileReviewResult implements Action {
  public readonly type = EJobsActions.ResetUpdateProfileReviewResult;
}

export class UpsertJobPreferencesIN implements Action {
  public readonly type = EJobsActions.UpsertJobPreferencesIN;

  constructor(public payload: JobPreferencesIN) {}
}

export class UpsertJobPreferencesINSuccess implements Action {
  public readonly type = EJobsActions.UpsertJobPreferencesINSuccess;

  constructor(public response: string) {}
}

export class UpsertJobPreferencesINError implements Action {
  public readonly type = EJobsActions.UpsertJobPreferencesINError;

  constructor(public error: Error) {}
}

export type JobsActions =
  | GetJobPreferences
  | GetJobPreferencesSuccess
  | GetJobPreferencesError
  | GetJobPreferencesINSuccess
  | GetJobPreferencesINError
  | SetJobPreferences
  | SetJobPreferencesSuccess
  | SetJobPreferencesError
  | ResetJobPreferenceSubmitResult
  | GetRecommendedJobsByPreference
  | GetRecommendedJobsByPreferenceSuccess
  | GetRecommendedJobsByPreferenceError
  | GetJobSubmittals
  | GetJobSubmittalsSuccess
  | GetJobSubmittalsError
  | GetJobSubmittalsIN
  | GetJobSubmittalsINSuccess
  | GetJobSubmittalsINError
  | ArchiveSubmittal
  | ArchiveSubmittalSuccess
  | ArchiveSubmittalError
  | SetSavedJob
  | SetSavedJobSuccess
  | SetSavedJobError
  | SetSavedRecommendedJob
  | SetSavedRecommendedJobSuccess
  | SetSavedAvailableJob
  | SetSavedAvailableJobSuccess
  | SetSavedSimilarJob
  | SetSavedSimilarJobSuccess
  | GetSavedJobs
  | GetSavedJobsSuccess
  | GetSavedJobsError
  | GetLocationsByName
  | GetLocationsByNameSuccess
  | GetLocationsByNameError
  | GetSpecificJob
  | GetSpecificJobSuccess
  | GetSpecificJobError
  | GetSpecificJobNotFound
  | GetSpecificAssignment
  | GetSpecificAssignmentSuccess
  | GetSpecificAssignmentError
  | ClearSpecificJob
  | GetSimilarJobsByFacilityId
  | GetSimilarJobsByFacilityIdSuccess
  | GetSimilarJobsByFacilityIdError
  | GetMapUrlForFacility
  | GetMapUrlForFacilitySuccess
  | GetMapUrlForFacilityError
  | GetAvailableJobs
  | GetAvailableJobsSuccess
  | GetAvailableJobsError
  | GetSimilarJobs
  | GetSimilarJobsSuccess
  | GetSimilarJobsSuccessMapped
  | GetSimilarJobsError
  | GetSimilarJobsByJobId
  | GetSimilarJobsByJobIdSuccess
  | GetSimilarJobsByJobIdError
  | GetJobFilterV2
  | SetJobFilterV2
  | SetContractType
  | GetHasAutoOfferAssignments
  | GetHasAutoOfferAssignmentsSuccess
  | GetHasAutoOfferAssignmentsError
  | UpdateJobNotifications
  | UpdateJobNotificationsSuccess
  | UpdateJobNotificationsError
  | CupidEventRecord
  | GetRecommendedMatchedJobs
  | GetRecommendedMatchedJobsSuccess
  | GetRecommendedMatchedJobsError
  | ApplyToSpecificJob
  | ApplyToSpecificJobSuccess
  | ApplyToSpecificJobError
  | UpdateApplication
  | UpdateApplicationSuccess
  | UpdateApplicationError
  | ResetApplyToSpecificJob
  | ResetUpdateApplication
  | GetApplication
  | GetApplicationSuccess
  | GetApplicationError
  | UpdateProfileReviewData
  | UpdateProfileReviewDataSuccess
  | UpdateProfileReviewDataError
  | ResetUpdateProfileReviewResult
  | UpsertJobPreferencesIN
  | UpsertJobPreferencesINSuccess
  | UpsertJobPreferencesINError;
