import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-clear-auto-complete',
  templateUrl: './clear-auto-complete.component.html',
  styleUrls: []
})
export class ClearAutoCompleteComponent {
  @Input() model: UntypedFormGroup;
  @Input() field: string;

  clear() {
    this.model.controls[this.field].setValue('');
    this.model.markAsDirty();
  }
}
