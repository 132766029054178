<app-auto-complete
  [form]="form"
  controlName="zipCode"
  placeholder="Zip Code"
  [filteredList]="filteredZipCodes"
  [displayFunction]="displayZipFn"
  (optionSelected)="invokeOptionSelectedCallback($event)"
  matErrorText="Invalid Zip Code"
  [required]="false"
></app-auto-complete>
