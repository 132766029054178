<div fxflex fxLayout="row">
  <div>
    <p>
      {{ message }} <a *ngIf="email !== ''" href="mailto:{{ email }}">{{ email }}</a
      >.
    </p>
  </div>
  <button id="snackBarErrorClose" mat-button color="basic" (click)="sbRef.dismiss()">
    <span>Close</span>
  </button>
</div>
