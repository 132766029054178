export class CandidateQualification {
  public id: string;
  public contactId: string;
  public fileName: string;
  public title: string;
  public fileDownloadUrl: string;
  public expirationDate: Date;
  public createdDate: Date;
  public statusReason: number;
  public category: number;
  public display = true;
  public docStatus: number;
  public expired: boolean;
}
