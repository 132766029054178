import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NursePortalApi } from 'src/app/services';

@Component({
  selector: 'app-declination-feedback-pop-up',
  templateUrl: './declination-feedback-pop-up.component.html',
  styleUrls: ['./declination-feedback-pop-up.component.scss']
})
export class DeclinationFeedbackPopUpComponent implements OnInit {
  form: UntypedFormGroup;
  declinationReasons = [
    'Accepted another offer from Health Carousel',
    'Accepted another offer from another company',
    'Inaccurate Job Description',
    'Accepted Permanent position'
  ];

  constructor(
    public dialogRef: MatDialogRef<DeclinationFeedbackPopUpComponent>,
    private _fb: UntypedFormBuilder,
    private _api: NursePortalApi
  ) {}

  ngOnInit() {
    this.form = this._fb.group({
      reasonForDeclination: new UntypedFormControl('', Validators.required)
    });
  }

  onSubmit() {
    const model = {
      truthyNess: true,
      declinationReason: this.form.controls.reasonForDeclination.value
    };
    this.dialogRef.close(model);
  }

  cancel(): void {
    const model = {
      truthyNess: false,
      declinationReason: null
    };
    this.dialogRef.close(false);
  }
}
