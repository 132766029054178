import { ArrivalsActions, EArrivalsActions } from './arrivals.actions';
import { IArrivalsState, initialArrivalsState } from './arrivals.state';

export function arrivalsReducer(state = initialArrivalsState, action: ArrivalsActions): IArrivalsState {
  switch (action.type) {
    case EArrivalsActions.GetArrivals:
      return {
        ...state,
        arrivals: {
          data: null,
          loading: true,
          error: null,
          hasFetched: false
        }
      };
    case EArrivalsActions.GetArrivalsSuccess:
      return {
        ...state,
        arrivals: {
          data: action.payload,
          loading: false,
          error: null,
          hasFetched: true
        }
      };
    case EArrivalsActions.GetArrivalsError:
      return {
        ...state,
        arrivals: {
          data: null,
          loading: false,
          error: action.error,
          hasFetched: true
        }
      };

    default:
      return state;
  }
}
