export class HelpModalTemplate {
  title: string;
  text?: string;
  subtext?: string;
  paragraphs?: HelpParagraph[];
  badgeTitle?: string;
  badges?: BadgeSection[];
  statusTitle?: string;
  statuses?: StatusSection[];
}

export class HelpParagraph {
  title?: string;
  icon?: string;
  icon_class?: string;
  text?: string;
}

export class BadgeSection {
  badge?: string;
  css_class?: string;
  text?: string;
}

export class StatusSection {
  status?: string;
  css_class?: string;
  text?: string;
}
