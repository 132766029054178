import { ILookup } from 'src/app/common/contracts/lookup';
import { Question } from 'src/app/common/models/question';
import { ICertificationLookupModel, IImmigrationStageLookupModel } from '../contracts/contracts';

export interface ILookups {
  professionLookup: Map<string, ILookup<string>>;
  specialtyLookup: Map<string, ILookup<string>>;
  technologyLookup?: Map<string, ILookup<string>>;
  requirementTypeLookup?: Map<number, ILookup<number>>;
  workExperienceLookup?: Map<number, ILookup<number>>;
  travelExperienceLookup?: Map<number, ILookup<number>>;
  shiftLookup?: Map<number, ILookup<number>>;
  weeklyHoursLookup?: Map<number, ILookup<number>>;
  documentStatusLookup?: Map<number, ILookup<number>>;
  stateLookup: Map<string, ILookup<string>>;
  countryLookup: Map<string, ILookup<string>>;
  licenseCertificationStatusLookup: Map<number, ILookup<number>>;
  certificationLookup: Map<string, ICertificationLookupModel>;
  languageTestTypeLookup: Map<string, number>;
  skillLookup?: Map<number, ILookup<string>>;
  groupLookup?: Map<number, any>;
  questionsLookup?: Map<number, Question>;
  yesNoLookup: Map<string, ILookup<string>>;
  yesNoIdLookup?: Map<number, string>;
  communicationPreferenceLookup: Map<string, ILookup<string>>;
  yesNoOnlyLookup: Map<string, ILookup<string>>;
  yesNoOnlyIdLookup: Map<number, string>;
  professionTypesLookup?: Map<string, ILookup<string>>;
  performanceScale?: Map<string, ILookup<string>>;
  degreeLookup: Map<number, ILookup<number>>;
  majorLookup: Map<number, ILookup<number>>;
  proficiencyLookup?: Map<number, ILookup<any>>;
  positionTitleLookup?: Map<number, any>;
  businessUnitLookup: Map<any, ILookup<any>>;
  displayCategoryLookup?: Map<number, string>;
  traumaLevelLookup: Map<number, ILookup<string>>;
  frequencyLookup?: Map<number, ILookup<string>>;
  issuingLookup: Map<string, ILookup<string>>;
  submittalStatusLookup?: Map<number, ILookup<number>>;
  compactCardDisplayStatuses?: Map<number, string>;
  bedSizeLookup?: Map<number, ILookup<number>>;
  erVisitsPerYearLookup?: Map<number, ILookup<number>>;
  birthsPerYearLookup?: Map<number, ILookup<number>>;
  startDatesLookup?: Map<number, ILookup<number>>;
  assignmentLengthsLookup?: Map<number, ILookup<number>>;
  assignmentSubstageLookup: Map<string, string>;
  registrationWorkExperience?: Map<number, ILookup<string>>;
  licenseLookup: Map<string, ILookup<string>>;
  immigrationStagesLookup?: Map<number, ILookup<IImmigrationStageLookupModel>>;
  interviewContactMethodLookup?: Map<number, ILookup<number>>;
  timeZoneLookup?: Map<number, ILookup<number>>;
  interviewTimeFrameLookup?: Map<number, ILookup<number>>;
  facilityTypeLookup?: Map<number, ILookup<number>>;
  communitySettingLookup?: Map<number, ILookup<number>>;
  preferenceTypeLookup?: Map<number, ILookup<number>>;
  zipCodeLookup?: Map<string, ILookup<string>>;
  phonePlanTypeLookup?: Map<number, ILookup<number>>;
}

export interface ISkillAreaInfoLookups {
  skillLookup: Map<number, ILookup<string>>;
  groupLookup: Map<number, any>;
}

export interface IQuestionsLookups {
  questionsLookup?: Map<number, Question>;
}

export interface IProfessionalHierarchy {
  id: string;
  name?: string;
  type?: string;
  children?: Array<IProfessionalHierarchy>;
}
